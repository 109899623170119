import { Typography } from '@mui/material'
import Modal from '../Modal/Modal'
import { ConfirmationNumber } from '@mui/icons-material'
import './ApproveRejectTray.scss'
import LoadingButton from '../LoadingButton/LoadingButton'

const ApproveRejectTray = ({
  open,
  onClose,
  isApproving,
  isRejecting,
  handleApproval,
  handleRejection,
}: {
  open: boolean
  onClose: () => void
  isApproving: boolean
  isRejecting: boolean
  handleApproval: () => void
  handleRejection: () => void
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="approve-reject-tray-molecule_container">
        <div className="header">
          <ConfirmationNumber />

          <Typography variant="h3">Approve Or Reject Tray</Typography>
        </div>

        <Typography variant="body1">
          Please confirm whether you want to approve or reject this tray.
        </Typography>

        <div className="confirmation-buttons_container">
          <LoadingButton
            loading={isRejecting}
            disabled={isApproving}
            mode="delete"
            onClick={handleRejection}
          >
            Reject
          </LoadingButton>

          <LoadingButton
            loading={isApproving}
            disabled={isRejecting}
            onClick={handleApproval}
          >
            Approve
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default ApproveRejectTray
