import { useUser } from 'app/User'
import { TrayStatus } from '../EditTrayModal/EditTrayModal.types'
import { useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { TrayType } from '../AddTraySelectType/AddTraySelectType.types'

const useEditTrayStatusModalLogic = ({
  trayCategory,
}: {
  trayCategory?: TrayType['category']
}) => {
  const { user } = useUser()

  const [selectedStatus, setSelectedStatus] = useState<TrayStatus>(undefined)

  const isSPD = user?.roles?.includes('MGAT_SPD')
  const trayStatuses: TrayStatus[] = isSPD
    ? trayCategory === 'facility'
      ? ['loaded', 'unAssigned']
      : ['approved', 'rejected', 'readyForPickup', 'readyToFill', 'unAssigned']
    : trayCategory === 'consigned'
    ? ['checkedOut']
    : []

  const handleSelectedStatusChange = (e: SelectChangeEvent<TrayStatus>) => {
    setSelectedStatus(e.target.value as TrayStatus)
  }

  return {
    trayStatuses,
    selectedStatus,
    handleSelectedStatusChange,
  }
}

export default useEditTrayStatusModalLogic
