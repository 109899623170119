import * as React from 'react'
import {
  Routes as RouterRoutes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import Invite from 'components/Invite'
import Layout from 'components/Layout'
import Logout from 'components/Logout'

import Dashboard from 'views/Dashboard'
import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import Login from 'views/Login/Login'
import MobileLogin from 'views/Login/MobileLogin'
import Procedures from 'views/Procedures'
import Procedure from 'views/Procedure/Procedure'
import Profile from 'views/Profile'
import StatusCheck from 'views/Status/StatusCheck'
import InventoryReport from 'views/InventoryReport/InventoryReport'
import Receive from 'views/ReceiveProducts/Receive'
import Scan from 'views/ReceiveProducts/scan/Scan'

import { useUser } from 'app/User'
import { BottomNavigationProvider } from 'lib/context/BottomNavigation'
import ProtectedUserRoute from 'app/ProtectedUserRoute'
import { DashboardLayout } from 'components/Dashboard/components/DashboardLayout'
import { UndocumentedAssetsProvider } from 'lib/context/UndocumentedAssetsContext'
import CaptureTray from 'views/DigitalTrayMapping/CaptureTray/CaptureTray'

import {
  ASSET_REPORTS,
  DASHBOARD,
  INVENTORY_REPORT,
  LOCATION_MANAGEMENT,
  RECEIVE,
  SCAN,
  USAGE_REPORTS,
  ANALYTICS,
} from './ROUTES_CONSTANTS'
import InventoryProvider from 'lib/apollo/inventory-config'
import InternalManagement from 'views/InternalManagement/InternalManagement'
import InternalManagementLayout from 'components/InternalManagementLayout/InternalManagementLayout'
import DTMAnalysisResults from 'views/InternalManagement/DTMAnalysisResults/DTMAnalysisResults'
import SPD from 'views/SPDLayout/SPD/SPD'
import { SPDProvider } from 'views/SPDLayout/SPD/SPD.context'
import LocationManagement from 'views/LocationManagement/LocationManagement'
import { TrayConfigurationProvider } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'
import PreferenceCards from 'views/PreferenceCards/PreferenceCards'
import CardView from 'views/PreferenceCards/CardView/CardView'
import { UsageReportsPage } from 'views/UsageReports/UsageReportsPage'
import { ProcedureDetailsReport } from 'views/UsageReports/ProcedureDetailsReport/ProcedureDetailsReport'
import { useDashboardContext } from 'lib/context/DashboardContext'
import AssetsReports from 'views/AssetsReports/AssetsReports'
import { SPDScanProvider } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { SPDCortexScannerProvider } from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner.context'
import { CaptureTrayContextProvider } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'
import { AssignedDigitalTraysProvider } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import SPDLayout from 'views/SPDLayout/SPDLayout'
import TrayManagementLayout from 'views/TrayManagementLayout/TrayManagementLayout'
import { InventoryReportProvider } from 'views/InventoryReport/InventoryReportProvider'
import { AssetTypeProvider } from 'lib/context/AssetTypeContext/AssetTypeContext'
import AddProcedure from 'views/AddProcedure/AddProcedure'
import Analytics from 'views/Analytics/Analytics'

declare global {
  interface Window {
    __inviteFlowStarted: boolean
  }
}

window.__inviteFlowStarted = false

function Routes() {
  const [accountChecked, setAccountChecked] = React.useState(false)
  const navigate = useNavigate()
  const { user, isAuthenticated } = useUser()
  const { darkMode } = useDashboardContext()

  const isInternalUser = user?.roles?.includes('MGAT_INTERNAL')
  const isSPDUser = user?.roles?.includes('MGAT_SPD')
  const pathToNavigateTo = isInternalUser
    ? 'internal'
    : isSPDUser
    ? 'tray-management'
    : 'surgeries'

  React.useEffect(() => {
    setAccountChecked(true)
  }, [accountChecked, navigate, user])

  if (!accountChecked) {
    return null
  }

  return (
    <RouterRoutes>
      <Route
        path="/"
        element={isAuthenticated ? <Navigate to="/home" replace /> : <Login />}
      />
      <Route path="/login" element={<MobileLogin />} />
      <Route
        path="invite/:inviteCode"
        element={
          <ProtectedUserRoute roles={['MGAT_REP']}>
            <Layout>
              <Invite />
            </Layout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="logout"
        element={
          <Layout>
            <Logout />
          </Layout>
        }
      />
      <Route
        path="home"
        element={<Navigate to={`/${pathToNavigateTo}`} replace />}
      />
      <Route
        path="surgeries"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSE', 'MGAT_REP']}>
            <Layout>
              <Procedures />
            </Layout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="new-procedure"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSE', 'MGAT_NURSEADMIN']}>
            <Layout>
              <AddProcedure />
            </Layout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={DASHBOARD}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout
              color={darkMode ? 'primary.dark' : 'grayscale.lightest'}
            >
              <Dashboard />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={USAGE_REPORTS}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <UsageReportsPage />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={`${USAGE_REPORTS}/:surgeryId/*`}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color={'grayscale.lightest'}>
              <ProcedureDetailsReport />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={USAGE_REPORTS}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="grayscale.lightest">
              <UsageReportsPage />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={ASSET_REPORTS}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <AssetsReports />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="spd/*"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSE', 'MGAT_NURSEADMIN']}>
            <AssignedDigitalTraysProvider>
              <CaptureTrayContextProvider>
                <TrayConfigurationProvider>
                  <SPDCortexScannerProvider>
                    <SPDProvider>
                      <SPDLayout>
                        <SPD />
                      </SPDLayout>
                    </SPDProvider>
                  </SPDCortexScannerProvider>
                </TrayConfigurationProvider>
              </CaptureTrayContextProvider>
            </AssignedDigitalTraysProvider>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="tray-management/*"
        element={
          <InventoryProvider>
            <CaptureTrayContextProvider>
              <SPDProvider>
                <SPDScanProvider>
                  <SPDCortexScannerProvider>
                    <InventoryReportProvider>
                      <TrayConfigurationProvider>
                        <TrayManagementLayout />
                      </TrayConfigurationProvider>
                    </InventoryReportProvider>
                  </SPDCortexScannerProvider>
                </SPDScanProvider>
              </SPDProvider>
            </CaptureTrayContextProvider>
          </InventoryProvider>
        }
      />
      <Route
        path="internal"
        element={
          <ProtectedUserRoute roles={['MGAT_INTERNAL']}>
            <InventoryProvider>
              <InternalManagementLayout />
            </InventoryProvider>
          </ProtectedUserRoute>
        }
      >
        <Route index element={<InternalManagement />} />
        <Route path="dtm-results" element={<DTMAnalysisResults />} />
      </Route>
      <Route
        path="surgeries/:surgeryId/*"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSE', 'MGAT_REP']}>
            <Layout>
              <AssignedDigitalTraysProvider>
                <SPDProvider>
                  <SPDScanProvider>
                    <AssetTypeProvider>
                      <UndocumentedAssetsProvider>
                        <BottomNavigationProvider>
                          <Procedure />
                        </BottomNavigationProvider>
                      </UndocumentedAssetsProvider>
                    </AssetTypeProvider>
                  </SPDScanProvider>
                </SPDProvider>
              </AssignedDigitalTraysProvider>
            </Layout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="profile"
        element={
          <ProtectedUserRoute
            roles={['MGAT_REP', 'MGAT_NURSE', 'MGAT_NURSEADMIN']}
          >
            <Layout>
              <Profile />
            </Layout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="capture-tray/:trayID"
        element={
          <ProtectedUserRoute
            roles={['MGAT_REP', 'MGAT_NURSE', 'MGAT_NURSEADMIN']}
          >
            <Layout>
              <CaptureTray />
            </Layout>
          </ProtectedUserRoute>
        }
      />
      {/* <Route
        path="audit-logs"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout>
              <AuditLogs />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="manage-reps"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout>
              <ManageRepsView />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      /> */}
      <Route
        path={INVENTORY_REPORT}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <InventoryProvider>
                <InventoryReport />
              </InventoryProvider>
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="preference-cards"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <PreferenceCards />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="preference-cards/new"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <CardView mode="create" />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path="preference-card/:id"
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <CardView mode="view" />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={LOCATION_MANAGEMENT}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <InventoryProvider>
                <LocationManagement />
              </InventoryProvider>
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route
        path={ANALYTICS}
        element={
          <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
            <DashboardLayout color="white">
              <Analytics />
            </DashboardLayout>
          </ProtectedUserRoute>
        }
      />
      <Route path={'/'} element={<DashboardLayout color="white" />}>
        <Route path={`${RECEIVE}/*`} element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
                <InventoryProvider>
                  <Receive />
                </InventoryProvider>
              </ProtectedUserRoute>
            }
          />
          <Route
            path={SCAN(':id')}
            element={
              <ProtectedUserRoute roles={['MGAT_NURSEADMIN']}>
                <InventoryProvider>
                  <Scan />
                </InventoryProvider>
              </ProtectedUserRoute>
            }
          />
        </Route>
      </Route>
      <Route path="status" element={<StatusCheck />} />
      <Route path="*" element={<ErrorFallback />} />
    </RouterRoutes>
  )
}

export default Routes
