import { useEffect, useState } from 'react'
import {
  CustomChangeEvent,
  ImplantReportEmailInput,
  ImplantReportStatus,
  ImplantReportType,
  Option,
  ReferringPhysician,
} from 'common/types'
import {
  ImplantReportSenderProps,
  ModalProps,
  physicianDetailsFormSchema,
} from './ImplantReportSender.types'
import {
  useAddReferringPhysician,
  useEditReferringPhysician,
  useGetReferringPhysician,
} from 'lib/apollo/hooks'
import { useIsMobile } from 'lib/utils/mediaQueries'
import toast from 'react-hot-toast'
import { getZodError } from 'common/utils'

export const useImplantReportSenderLogic = ({
  assetGroups,
  surgeryId,
  implantReportStatus,
}: ImplantReportSenderProps) => {
  const isMobile = useIsMobile()

  const assetIds = assetGroups.flatMap((item) =>
    item.scans
      .filter(
        (item) =>
          item.assetType === 'biological' ||
          item.assetType === 'non-biological' ||
          item.assetType === 'other-non-biological'
      )
      .map((scan) => scan._id)
  )

  const initialPhysicianDetails: ReferringPhysician = {
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
  }

  // States
  const [physicianDetails, setPhysicianDetails] = useState<ReferringPhysician>(
    initialPhysicianDetails
  )
  const [updatedPhysicianDetails, setUpdatedPhysicianDetails] =
    useState<ReferringPhysician>(initialPhysicianDetails)
  const [openModal, setOpenModal] = useState<ModalProps>({
    open: false,
  })
  const [formErrors, setFormErrors] = useState<ReferringPhysician>()
  const [search, setSearch] = useState('')
  const [selectedPhysician, setSelectedPhysician] = useState<Option>()
  const [inputModalOpen, setInputModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [mutationSuccess, setMutationSuccess] = useState<boolean>()
  const [isReportSent, setIsReportSent] = useState(false)
  const [viewReportsModalOpen, setViewReportsModalOpen] = useState(false)
  const [implantReportInputs, setImplantReportInputs] =
    useState<ImplantReportEmailInput>({
      surgeryId,
      reportType: undefined,
      reportInputs: {
        assetIds,
      },
      submitOverride: false,
    })

  // Services
  const { data: physicianData, loading: physicianLoading } =
    useGetReferringPhysician(search)

  const [addReferringPhysician, { loading: isAdding }] =
    useAddReferringPhysician()

  const [editReferringPhysician, { loading: isEditing }] =
    useEditReferringPhysician()

  const physicians = physicianData?.getPhysician || []
  const lastNameOptions: Option[] = physicians.map((item) => ({
    name: item.lastName as string,
    id: item._id as string,
  }))

  // Functions
  const formatEnumValue = (value: keyof typeof ImplantReportType) => {
    return value
      ?.toLowerCase()
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const unFormatEnumValue = (value: string): ImplantReportType => {
    const formattedValue = value
      .split(' ')
      .map((word) => word.toUpperCase())
      .join('_')
    return (
      ImplantReportType[formattedValue as keyof typeof ImplantReportType] ||
      null
    )
  }

  // Constants
  const isReferringInfoValid =
    physicianDetails?.lastName && physicianDetails.email

  const formattedReportTypes =
    Object.values(ImplantReportType).map(formatEnumValue)

  const modalHeader =
    openModal.type === 'create'
      ? 'Add Referring Physician'
      : 'Edit Referring Physician'

  const disabledOption = (option: string | Option) => {
    const opt = typeof option === 'object' ? option.name : option
    const enumType = unFormatEnumValue(opt)
    if (option === 'Other') {
      return false
    } else {
      return !(implantReportStatus as ImplantReportStatus[]).some(
        (status) => status.type === enumType && status.isSent
      )
    }
  }

  // Handlers
  const handleSelectPhysician = (e: CustomChangeEvent) => {
    const value = e.target.value as Option
    setSelectedPhysician(value)

    const selected = physicians.find((item) => item._id === value.id)

    if (selected) {
      setPhysicianDetails(selected)
      setImplantReportInputs((prev) => ({
        ...prev,
        referringPhysicianEmail: selected.email,
        referringPhysicianLastName: selected.lastName,
      }))
    }
  }

  const handleEditClick = () => {
    setOpenModal({ open: true, type: 'edit' })
    if (physicianDetails) {
      setUpdatedPhysicianDetails({
        ...physicianDetails,
        confirmEmail: physicianDetails.email,
      })
    }
  }

  const handleAddPhysicianClick = () => {
    setOpenModal({
      open: true,
      type: 'create',
    })
    setUpdatedPhysicianDetails((prev) => ({
      ...prev,
      lastName: search,
    }))
  }

  const handleCloseModal = () => {
    setOpenModal({ open: false })
    setUpdatedPhysicianDetails(initialPhysicianDetails)
    setFormErrors({})
  }

  const handleChangeUpdatedPhysicianDetails = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormErrors((prev) => ({ ...prev, [e.target.name]: '' }))
    setUpdatedPhysicianDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    if (
      openModal.type === 'edit' &&
      updatedPhysicianDetails.email === physicianDetails.email
    ) {
      setUpdatedPhysicianDetails((prev) => ({
        ...prev,
        confirmEmail: physicianDetails.email,
      }))
    } else if (openModal.type === 'edit') {
      setUpdatedPhysicianDetails((prev) => ({
        ...prev,
        confirmEmail: '',
      }))
    }
  }, [openModal.type, physicianDetails.email, updatedPhysicianDetails.email])

  const handleAddPhysician = async () => {
    const result = physicianDetailsFormSchema.safeParse(updatedPhysicianDetails)
    if (!result.success) {
      setFormErrors(getZodError(result.error))
      return
    }

    const { _id, email, firstName, lastName } = updatedPhysicianDetails

    if (openModal.type === 'create') {
      addReferringPhysician({
        variables: {
          physician: {
            email,
            lastName,
            firstName,
          },
        },
      })
        .then((res) => {
          if (res.data?.createPhysician) {
            const result = res.data?.createPhysician
            handleSuccess(result)
          }
        })
        .catch((err: Error) => {
          toast.error(err.message)
        })
    }
    if (openModal.type === 'edit' && _id) {
      editReferringPhysician({
        variables: {
          physician: {
            _id,
            email,
            lastName,
            firstName,
          },
        },
      })
        .then((res) => {
          if (res.data?.editPhysician) {
            const result = res.data?.editPhysician
            handleSuccess(result)
          }
        })
        .catch((err) => {
          toast.error(err.message)
        })
    }
  }

  const handleSuccess = (result: ReferringPhysician) => {
    const action = openModal.type
    const message = `Physician ${
      action === 'create' ? 'added' : 'updated'
    } successfully.`

    setPhysicianDetails(result)
    setUpdatedPhysicianDetails(initialPhysicianDetails)
    setSelectedPhysician({
      id: result._id as string,
      name: result.lastName as string,
    })
    setOpenModal({ open: false })
    toast.success(message)
  }

  // Lifecycle Methods
  useEffect(() => {
    if (implantReportStatus) {
      setIsReportSent(implantReportStatus.some((status) => status.isSent))
    }
  }, [implantReportStatus])

  return {
    search,
    isMobile,
    assetIds,
    isAdding,
    openModal,
    isEditing,
    isLoading: isAdding || isEditing,
    formErrors,
    modalHeader,
    errorMessage,
    isReportSent,
    inputModalOpen,
    mutationSuccess,
    lastNameOptions,
    physicianLoading,
    physicianDetails,
    selectedPhysician,
    implantReportInputs,
    isReferringInfoValid,
    viewReportsModalOpen,
    formattedReportTypes,
    updatedPhysicianDetails,
    setSearch,
    disabledOption,
    setErrorMessage,
    formatEnumValue,
    handleEditClick,
    handleCloseModal,
    unFormatEnumValue,
    setInputModalOpen,
    setMutationSuccess,
    handleAddPhysician,
    handleSelectPhysician,
    setImplantReportInputs,
    setViewReportsModalOpen,
    handleAddPhysicianClick,
    setUpdatedPhysicianDetails,
    handleChangeUpdatedPhysicianDetails,
  }
}
