import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AssetData, Scan, ScanState } from 'common/types'
import { useParams } from 'react-router-dom'
import { DispositionData } from 'common/disposition'
import { useEditDocumentedAssets } from 'lib/apollo/hooks'
import {
  EditDocumentedListContextProps,
  EditDocumentedListProviderProps,
  EditDocumentedScanInput,
} from './EditDocumentedList.types'

export const EditDocumentedListContext =
  createContext<EditDocumentedListContextProps>({
    isSavingEditedAsset: () => false,
    scanStateMap: {},
    setScanStateMap: () => {},
    foundScanRef: { current: undefined },
    scans: [],
    setScans: () => {},
    submitDisposition: async () => false,
    isSelectModalOpen: false,
    setIsSelectModalOpen: () => {},
    isEditModalOpen: false,
    setIsEditModalOpen: () => {},
    isLoading: false,
    assetGroupId: '',
    setAssetGroupId: () => {},
    handleReset: () => {},
    scanIsMatching: () => false,
    consumableAssetGroup: undefined,
    setConsumableAssetGroup: () => {},
    mutationStatus: undefined,
    showStatusBar: false,
  })

export function EditDocumentedListProvider({
  children,
}: EditDocumentedListProviderProps) {
  // Hooks
  const { surgeryId } = useParams()

  const [editDocumentedAssets, editDocumentedAssetsMutation] =
    useEditDocumentedAssets(surgeryId || '')

  // States
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false)
  const [scans, setScans] = useState<Scan[]>([])
  const [assetGroupId, setAssetGroupId] = useState<AssetData['_id']>('')
  const [consumableAssetGroup, setConsumableAssetGroup] = useState<AssetData>()
  const [scanStateMap, setScanStateMap] = useState<
    Record<Scan['_id'], ScanState>
  >({})
  const [mutationStatus, setMutationStatus] =
    useState<EditDocumentedListContextProps['mutationStatus']>(undefined)
  const [showStatusBar, setShowStatusBar] = useState(false)

  const foundScanRef = useRef<Scan | undefined>() // used to store the scan that is selected

  // Lifecycle methods
  useEffect(() => {
    foundScanRef.current = scans?.find(
      (scan) => scanStateMap[scan._id]?.isSelected
    )!
  }, [scanStateMap, scans])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (mutationStatus !== undefined) {
      setShowStatusBar(true)

      timer = setTimeout(() => {
        setShowStatusBar(false)
        setMutationStatus(undefined)
        setConsumableAssetGroup(undefined)
        setAssetGroupId('')
      }, 5000)
    }

    // Cleanup timer to prevent memory leaks
    return () => clearTimeout(timer)
  }, [mutationStatus, setMutationStatus])

  // Handlers
  const handleReset = () => {
    setScanStateMap({})
    setScans([])
    foundScanRef.current = undefined
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitDisposition = async (
    data: DispositionData,
    scanObject: EditDocumentedScanInput
  ): Promise<boolean> => {
    try {
      const result = await editDocumentedAssets({
        variables: {
          surgeryId: surgeryId,
          disposition: data,
          scans: [scanObject],
        },
      })

      if (result.data?.editDocumentedAssets?.success) {
        setMutationStatus(true)
        handleReset()
        return true
      } else {
        setMutationStatus(false)
        return false
      }
    } catch (error: any) {
      setMutationStatus(false)
      return false
    }
  }

  const value = useMemo(() => {
    const scanIsMatching = () => {
      return scans.map((scan) => scan._id).includes(foundScanRef.current?._id!)
    }

    const isSavingEditedAsset = (agId: AssetData['_id']) => {
      return editDocumentedAssetsMutation.loading && agId === assetGroupId
    }

    return {
      scanStateMap,
      setScanStateMap,
      foundScanRef,
      scans,
      setScans,
      submitDisposition,
      isSavingEditedAsset,
      isSelectModalOpen,
      setIsSelectModalOpen,
      isEditModalOpen,
      setIsEditModalOpen,
      isLoading: editDocumentedAssetsMutation.loading,
      assetGroupId,
      setAssetGroupId,
      handleReset,
      scanIsMatching,
      consumableAssetGroup,
      setConsumableAssetGroup,
      mutationStatus,
      showStatusBar,
    }
  }, [
    scanStateMap,
    scans,
    submitDisposition,
    isSelectModalOpen,
    isEditModalOpen,
    editDocumentedAssetsMutation.loading,
    assetGroupId,
    consumableAssetGroup,
    mutationStatus,
    showStatusBar,
  ])

  return (
    <EditDocumentedListContext.Provider value={value}>
      {children}
    </EditDocumentedListContext.Provider>
  )
}

export function useEditDocumentedListContext() {
  const context = React.useContext(EditDocumentedListContext)
  if (context === undefined) {
    throw new Error(
      'useEditDocumentedListContext must be used within a EditDocumentedListProvider'
    )
  }
  return context
}
