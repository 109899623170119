import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import { useAutocompleteInputLogic } from './AutocompleteInput.logic'
import { Option } from 'common/types'
import { AutocompleteInputProps } from './AutocompleteInput.types'

function AutocompleteInput({
  id,
  disabled = false,
  handleChangeFormData,
  label,
  name,
  options,
  placeholder = 'Select',
  required = false,
  value = '',
  onTextInputChange,
  sx,
  variant = 'standard',
  autoSelect = true,
  clearOnBlur,
  autoHighlight = true,
  isLoading = false,
  disableClearable = false,
  dataTestId,
  noOptionsText,
  toolTip,
  getOptionDisabled,
  fullWidth = false,
  addOption = false,
  isEmpty = false,
  handleModalOnClose,
  errorMessage,
  helperText,
  error = false,
  shrink = true,
  ...props
}: AutocompleteInputProps) {
  const { isFilled, autocompleteStyles, renderItem } =
    useAutocompleteInputLogic({
      disabled,
      required,
      variant,
      sx,
      value,
      toolTip,
      getOptionDisabled,
      handleChangeFormData,
      name,
      error,
    })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Autocomplete
        data-testid={dataTestId}
        className={props.className}
        autoSelect={autoSelect}
        disabled={disabled}
        id={id}
        fullWidth={fullWidth}
        options={options as readonly (string | Option)[]}
        value={value as string | Option}
        clearIcon={<ClearIcon fontSize="medium" />}
        getOptionLabel={(option: string | Option): string => {
          if (typeof option === 'object') {
            return option.name
          }
          return option
        }}
        onChange={(_, inputValue) => {
          handleChangeFormData({
            target: { name: name ?? '', value: inputValue ?? '' },
          })
        }}
        sx={autocompleteStyles}
        renderInput={(params) => (
          <TextField
            helperText={helperText}
            error={error}
            data-testid={`${dataTestId}-textfield`}
            {...params}
            label={label}
            placeholder={placeholder}
            variant={variant}
            onChange={(e) => {
              if (onTextInputChange) {
                onTextInputChange({
                  target: {
                    name: name || '',
                    value: e.target.value,
                  },
                })
              }
            }}
            autoComplete="off"
            InputLabelProps={{
              shrink,
            }}
          />
        )}
        {...(toolTip
          ? {
              renderOption: (props, option: string | Option) =>
                renderItem(props, option),
            }
          : {})}
        clearOnBlur={clearOnBlur}
        autoHighlight={autoHighlight}
        loadingText="Loading..."
        loading={isLoading}
        disableClearable={disableClearable}
        noOptionsText={noOptionsText}
      />
      {required && !isFilled && (
        <Typography
          variant="caption"
          sx={{ color: 'error.main', ml: '0.5em', mb: '0.5em' }}
        >
          Required
        </Typography>
      )}
      {errorMessage && (
        <Typography
          fontSize={12}
          color="red"
          ml={0.5}
          mt={variant === 'outlined' ? 0 : -2}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  )
}

export default AutocompleteInput
