import { FilterList } from '@mui/icons-material'
import classNames from 'classnames'

import TrayManagementFilters from 'components/organisms/TrayManagementFilters/TrayManagementFilters'
import EditTrayModal from 'components/organisms/EditTrayModal/EditTrayModal'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { LoadingButton } from 'components/mui'

import { SPDCortexScannerProvider } from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner.context'
import { useTrayConfigurationContext } from './TrayManagement.context'
import useTrayManagementLogic from './TrayManagement.logic'

import { ItemResponse } from 'common/types'
import './TrayManagement.scss'
import EditTrayStatusModal from 'components/organisms/EditTrayStatusModal/EditTrayStatusModal'

const TrayManagement = () => {
  const {
    trays,
    loadingTrays,
    error,
    handleFiltersNavbarMenuClick,
    isFiltersNavbarOpen,
    isListOfSurgeriesOpen,
    selectedTray,
  } = useTrayConfigurationContext()
  const {
    trayTableBodyCells,
    trayTableHeaderCells,
    handleListOfSurgeriesClose,
    isEditTrayStatusModalOpen,
    handleEditTrayStatusClick,
    handleEditTrayStatusChange,
    selectedTrayCategory,
  } = useTrayManagementLogic()

  return (
    <div
      data-testid="tray-management_wrapper"
      className="tray-management_wrapper"
    >
      <div className="filters_container">
        <LoadingButton
          variant="contained"
          className="side-nav_toggle"
          onClick={handleFiltersNavbarMenuClick}
        >
          <FilterList />
        </LoadingButton>
        <TrayManagementFilters />
      </div>

      <div
        className={classNames('table_container', {
          open: isFiltersNavbarOpen,
        })}
      >
        <DataTable
          tableHeaders={trayTableHeaderCells}
          tableRows={trayTableBodyCells(trays)}
          isLoading={loadingTrays}
          isErrored={!!error}
          disableSorting
        />
      </div>

      <EditTrayStatusModal
        open={isEditTrayStatusModalOpen}
        onClose={() => handleEditTrayStatusClick(undefined)}
        onConfirm={handleEditTrayStatusChange}
        trayCategory={selectedTrayCategory}
      />

      {selectedTray && (
        <EditTrayModal
          open={isListOfSurgeriesOpen && !!selectedTray}
          onClose={handleListOfSurgeriesClose}
          trayData={selectedTray?.trayItem as ItemResponse}
        />
      )}
    </div>
  )
}

export default TrayManagement
