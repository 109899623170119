import { useEffect, useMemo, useState, useRef, FC } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  FormControl,
  Paper,
  Typography,
  LinearProgress,
} from '@mui/material'
import { ArrowBack, Check, QrCode, Search } from '@mui/icons-material'

import { ConsumableModal } from '../../../components/Consumables/ConsumableModal'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { ScannerFormatSwitcher } from 'components/ScannerFormatSwitcher'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import SelectInput from 'components/SelectInput/SelectInput'
import { AssetDetailsCard } from '../AssetDetailsCard/AssetDetailsCard'
import { facility } from 'common/facilityMigration'
import TextInput from 'components/molecules/TextInput/TextInput'

import { useUser } from 'app/User'
import { useCortexDecoderContext, FormatType } from 'lib/cortex'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { CurrentScanDetails } from 'lib/scan-handler'
import { BarcodeIcon } from 'assets/icons/BarcodeIcon'
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import useFormValidation from 'lib/hooks/useFormValidation/useFormValidation'
import { addToAssetListValidationSchema } from './form/addToAssetListValidationSchema'
import { getEnumKeyByKey } from 'lib/utils/enumUtils'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { getAssetTypeCaps } from 'lib/utils/getAssetType'

import {
  AddProcedureAssetScanMutation,
  AssetFormData,
  AssetData,
  Surgery,
  AssetGoodConditionCheck,
  SamplePreparationType,
  MainIconFlowState,
  NewAsset,
  ManualInputFormData,
  MediaAction,
} from 'common/types'
import styles from './result.module.css'
import {
  ASSET_TYPES,
  DISPOSITIONS,
  EXPLANTED_REASON_LIST,
} from 'common/disposition'
import MediaUpload from 'components/organisms/MediaUpload/MediaUpload'
import { MediaFile } from 'components/organisms/MediaUpload/MediaUpload.types'
import { z } from 'zod'
import { getZodError } from 'common/utils'

interface ResultProps {
  addSurgeryAssetScanMutation: AddProcedureAssetScanMutation
  assetFormData: AssetFormData
  handleAddToAssetList: () => void
  handleChangeTray: (e: any) => void
  handleChangeAssetType: (e: any) => void
  handleChangeSerialNumber: (e: any) => void
  createDispositionsChangeHandler: (index: number) => (e: any) => void
  resetAssetFormData: () => void
  assetGroups: AssetData[]
  surgery: Surgery
  scanDetails: CurrentScanDetails
  mainIconFlowState: MainIconFlowState
  setUserCanScan: (value: boolean) => void
  onMediaFilesChange?: (files: MediaFile[], action: MediaAction) => void
}

const Result: FC<ResultProps> = ({
  addSurgeryAssetScanMutation,
  assetFormData,
  handleAddToAssetList,
  handleChangeAssetType,
  handleChangeTray,
  handleChangeSerialNumber,
  createDispositionsChangeHandler,
  resetAssetFormData,
  assetGroups,
  surgery,
  setUserCanScan,
  scanDetails,
  mainIconFlowState,
  onMediaFilesChange,
}) => {
  const [searchParams] = useSearchParams()

  // BEGIN form validation / alerts
  const {
    isFormSubmitted,
    handleValidation,
    FormValidationAlertToasters,
    isFormValidated,
  } = useFormValidation({
    validationSchema: addToAssetListValidationSchema,
  })
  const [isAssetAdded, setIsAssetAdded] = useState(false)

  const [validationErrors, setValidationErrors] = useState<Record<
    string,
    string
  > | null>(null)

  useEffect(() => {
    setValidationErrors(null)
  }, [assetFormData])

  const assetFormDataValidationSchema = z
    .object({
      assetData: z.enum(ASSET_TYPES).optional(),
      finalDisposition: z.enum(DISPOSITIONS).optional(),
      implantSite: z.string().optional(),
      siteLocation: z.string().optional(),
      samplePreparation: z.string().optional(),
      samplePreparationNote: z.string().optional(),
      wastedReason: z.string().optional(),
      explantedReason: z.string().optional(),
      explantedReasonNote: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'EXPLANTED' &&
        !data.explantedReason
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Explanted reason cannot be empty',
          path: ['explantedReason'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'EXPLANTED' &&
        data.explantedReason === 'OTHER' &&
        !data.explantedReasonNote
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Other reason cannot be empty',
          path: ['explantedReasonNote'],
        })
      }
      if (!data.finalDisposition) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Final Disposition cannot be empty',
          path: ['finalDisposition'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'IMPLANTED' &&
        !data.samplePreparation
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Sample preparation cannot be empty',
          path: ['samplePreparation'],
        })
      }
      if (
        data.finalDisposition === 'IMPLANTED' ||
        data.finalDisposition === 'EXPLANTED'
      ) {
        if (!data.implantSite) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              data.finalDisposition === 'IMPLANTED'
                ? 'Implant site cannot be empty'
                : 'Explant site cannot be empty',
            path: ['implantSite'],
          })
        } else if (
          (data.implantSite === 'Other' || data.implantSite === 'Mouth') &&
          !data.siteLocation
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              data.finalDisposition === 'IMPLANTED'
                ? 'Implant location cannot be empty'
                : 'Explant location cannot be empty',
            path: ['siteLocation'],
          })
        }
      }
      if (data.finalDisposition === 'WASTED' && !data.wastedReason) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Wasted Reason cannot be empty',
          path: ['wastedReason'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.samplePreparation === 'OTHER' &&
        !data.samplePreparationNote
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Sample preparation note cannot be empty',
          path: ['samplePreparationNote'],
        })
      }
    })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      Object.keys(assetFormData.dispositions).length <= 1 ||
      !assetFormData.dispositions
    ) {
      if (assetFormData.assetType !== 'consumable') {
        if (!assetFormData.dispositions[0]) {
          setValidationErrors({
            finalDisposition: 'Disposition cannot be empty',
          })
          return
        } else {
          const result = assetFormDataValidationSchema.safeParse(
            assetFormData.dispositions[0]
          )
          if (!result.success) {
            setValidationErrors(getZodError(result.error))
            return
          }
        }
      }
    }

    handleValidation(e)
    if (isFormValidated) {
      handleAddToAssetList()
    }
  }

  useEffect(() => {
    if (isFormSubmitted && isFormValidated && !isAssetAdded) {
      handleAddToAssetList()
      setIsAssetAdded(true)
    }
  }, [isFormSubmitted, handleAddToAssetList, isFormValidated, isAssetAdded])
  // END form validation / alerts

  // Update asset type field based upon mainIconFlowState
  useEffect(() => {
    const assetType = getAssetTypeCaps(mainIconFlowState.assetTypeLabel)
    const event = {
      target: {
        name: 'assetType',
        value: '',
      },
    }

    event.target.value = assetType

    handleChangeAssetType(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // leave empty to only run once

  const { selectedResult } = scanDetails
  const result = scanDetails?.selectedResult as ManualInputFormData
  const isManualAddition = result?.isManualAddition

  const { assetTypes, wastedReasons } = facility
  const { implantSites } = useImplantSites()
  const implantSiteList = implantSites.map((implantSite) => implantSite.name)

  const navigate = useNavigate()
  const { isRep, isNurse } = useUser()
  const {
    currentScan,
    setCurrentScan,
    trayScannerState,
    isActive,
    formatType,
    setFormatType,
  } = useCortexDecoderContext()
  const originalFormatRef = useRef(formatType)
  const prevScanRef = useRef(currentScan)

  const groupId =
    selectedResult && 'id' in selectedResult ? selectedResult.id : undefined

  const {
    deviceDescription,
    deviceId,
    deviceCount,
    sizeText,
    expirationDate,
    secondaryDeviceIdType,
    lotBatch,
    catalogNumber,
    versionModelNumber,
    company,
    serialNumber,
  } = (selectedResult as NewAsset) ?? { deviceCount: 1, expirationDate: null }

  // multipack consumables
  const [consumableModalOpen, setConsumableModalOpen] = useState(false)
  const [multipackItemsUsed, setMultipackItemsUsed] = useState(0)

  const handleConsumableModalClose = () => {
    setConsumableModalOpen(false)
    createDispositionsChangeHandler(0)({
      target: {
        name: 'consumableCount',
        value: multipackItemsUsed,
      },
    })
  }

  const {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackNonConsumable,
  } = parseAssetIdentifiers({
    deviceDescription,
    deviceCount,
    idType: secondaryDeviceIdType,
    assetType: assetFormData.assetType || '',
  })

  // BEGIN Tray Scanner Logic
  const maybeSetTrayScanner = trayScannerState?.[1]

  // TODO Temporary solution for ticket MGAT-734
  // The problem is that after successful scanning of an implantable biologic, the user is
  // redirected to this component /result.
  // When clicking back, the user is going back to /scan, then again to /result,
  // but this time without the scanDetails, so the user sees the component with undefined values.
  // The temp solution is to take the user back to /scan if scanDetails.isComplete is false
  if (
    !scanDetails.selectedResult &&
    searchParams.get('src') !== 'manual-asset-search'
  ) {
    navigate('../scan')
  }

  // TODO
  // I had to use type any here to resolve the error type.
  // Will look into that later.
  const isTrayScannerOpen: any = trayScannerState?.[0] ?? false
  const setIsTrayScannerOpen: any = useMemo(
    () => maybeSetTrayScanner ?? (() => {}),
    [maybeSetTrayScanner]
  )

  useEffect(() => {
    setCurrentScan(undefined)
    return () => {
      setIsTrayScannerOpen(false)
      setUserCanScan(false)
      setCurrentScan(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsTrayScannerOpen, setCurrentScan])

  useEffect(() => {
    if (isTrayScannerOpen) {
      const originalFormat = originalFormatRef.current
      setFormatType(FormatType['1d'])
      return () => {
        if (originalFormat) {
          setFormatType(originalFormat)
        }
      }
    }
  }, [setFormatType, isTrayScannerOpen])
  // END Tray Scanner Logic

  useEffect(() => {
    if (
      currentScan !== undefined &&
      prevScanRef.current?.barcodeData !== currentScan?.barcodeData
    ) {
      if (isTrayScannerOpen === 'tray') {
        handleChangeTray({
          target: {
            value: currentScan?.barcodeData,
          },
        })
        setIsTrayScannerOpen(false)
        setUserCanScan(false)
        setCurrentScan(undefined)
      } else if (isTrayScannerOpen === 'serial') {
        handleChangeSerialNumber({
          target: {
            value: currentScan?.barcodeData,
          },
        })
        setIsTrayScannerOpen(false)
        setUserCanScan(false)
        setCurrentScan(undefined)
      }
    }
    if (!prevScanRef.current && currentScan) {
      prevScanRef.current = currentScan
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentScan,
    isTrayScannerOpen,
    setIsTrayScannerOpen,
    handleChangeTray,
    handleChangeSerialNumber,
    setCurrentScan,
  ])

  const matchedAssetGroup = useMemo(
    () => assetGroups.find((group) => group.bidAssetId === groupId),
    [assetGroups, groupId]
  )

  useEffect(() => {
    return () => {
      resetAssetFormData()
      addSurgeryAssetScanMutation.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (surgery.activeAssetTray) {
      handleChangeTray({
        target: {
          name: 'tray',
          value: surgery.activeAssetTray,
        },
      })
    }
  }, [surgery.activeAssetTray, handleChangeTray])

  useEffect(() => {
    if (assetFormData.assetType !== 'non-biological' && isRep) {
      handleChangeAssetType({
        target: {
          name: 'assetType',
          value: 'non-biological',
        },
      })
    }
    if (
      !isRep &&
      matchedAssetGroup &&
      matchedAssetGroup?.scans[0]?.assetType &&
      (!assetFormData.assetType ||
        assetFormData.assetType !== matchedAssetGroup?.scans[0]?.assetType)
    ) {
      handleChangeAssetType({
        target: {
          name: 'assetType',
          value: matchedAssetGroup.scans[0].assetType,
        },
      })
    }
  }, [assetFormData, handleChangeAssetType, matchedAssetGroup, isRep])

  const isAssetAddedToList =
    addSurgeryAssetScanMutation.data && !addSurgeryAssetScanMutation.error

  const isAssetFieldDisabled =
    addSurgeryAssetScanMutation.loading ||
    addSurgeryAssetScanMutation.data !== undefined

  const finalDisposition = [
    { value: 'IMPLANTED', label: 'Implanted' },
    { value: 'WASTED', label: 'Wasted' },
  ]

  if (isNurse) {
    finalDisposition.push({ value: 'EXPLANTED', label: 'Explanted' })
  }

  if (
    assetFormData.assetType === 'non-biological' &&
    !isMultipackNonConsumable
  ) {
    finalDisposition.splice(1, 0, {
      value: 'ASSOCIATED_ASSET',
      label: 'Utilized associated product',
    })
  }

  if (isMultipackNonConsumable) {
    finalDisposition.push({
      value: 'UNUSED',
      label: 'Unused Product',
    })
  }

  useEffect(() => {
    if (assetFormData.assetType === 'consumable') {
      const handleConsumableChange = () => {
        resetAssetFormData()
        handleChangeAssetType({
          target: {
            name: 'assetType',
            value: 'consumable',
          },
        })
        createDispositionsChangeHandler(0)({
          target: {
            name: 'consumableCount',
            value: 1,
          },
        })
      }

      if (
        isMultipackConsumable &&
        !isRfMultipackConsumable &&
        !isSpongeConsumable
      ) {
        setConsumableModalOpen(true)
        handleConsumableChange()
      } else if (
        isRfMultipackConsumable ||
        assetFormData.assetType === 'consumable' ||
        isSpongeConsumable
      ) {
        handleConsumableChange()
      }
    }
    if (deviceCount > 1 && assetFormData.assetType !== 'consumable') {
      Array.from({ length: deviceCount }).forEach((_, i) => {
        createDispositionsChangeHandler(i)({
          target: {
            name: 'finalDisposition',
            value: 'UNUSED',
          },
        })
      })
    }
  }, [
    deviceCount,
    createDispositionsChangeHandler,
    assetFormData.assetType,
    isMultipackConsumable,
    isRfMultipackConsumable,
    resetAssetFormData,
    handleChangeAssetType,
    isSpongeConsumable,
  ])

  if (isTrayScannerOpen) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 1,
        }}
      >
        {!isActive && (
          <Box
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            position="absolute"
            sx={{
              backgroundColor: 'white',
            }}
          >
            <Box textAlign="center">
              <Typography
                variant="h3"
                color="grey.800"
                my={6}
                letterSpacing={1}
                fontWeight={500}
                fontSize={18}
              >
                {`Preparing ${'Tray'} Smart Vision`}
              </Typography>
              <LinearProgress />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            position: 'fixed',
            top: 100,
            zIndex: 99999,
            padding: 1,
            borderRadius: 2,
            backgroundColor: 'rgba(0, 0, 0, .5)',
          }}
        >
          <ScannerFormatSwitcher noPersist />
        </Box>
        <Button
          sx={{ bottom: '72px', position: 'fixed', zIndex: 999999999999999 }}
          variant="contained"
          onClick={() => {
            setIsTrayScannerOpen(false)
            setUserCanScan(false)
          }}
          disabled={!isActive}
        >
          Close Image Capture
        </Button>
      </div>
    )
  }

  const renderSiteLocation = (index: number) =>
    isSiteLocationEnabled(index) && (
      <TextInput
        sx={{ mt: 0, mb: 1 }}
        id={`implant-site-${index}`}
        label={
          isExplanted(index) && 'IMPLANTED'
            ? 'Explant Location*'
            : 'Implant Location*'
        }
        name={`siteLocation-${index}`}
        disabled={isAssetFieldDisabled}
        value={assetFormData.dispositions[index].siteLocation}
        placeholder={
          isExplanted(index) && 'IMPLANTED'
            ? 'Explant Location*'
            : 'Implant Location*'
        }
        inputMode="text"
        handleChangeFormData={(e) => {
          const { name, value } = e.target
          const parsedName = name.split('-').shift()
          if (parsedName) {
            if (assetFormData.dispositions[index]?.implantSite === 'Mouth') {
              if (parsedName === 'siteLocation' && value.length <= 4) {
                createDispositionsChangeHandler(index)(e)
              }
            } else {
              createDispositionsChangeHandler(index)(e)
            }
          }
        }}
        errorMessage={validationErrors?.siteLocation}
        shrink={true}
      />
    )

  const renderExplantedReasonNote = (index: number) =>
    assetFormData.dispositions[index]?.explantedReason === 'OTHER' && (
      <TextInput
        id={`explanted-reason-note-${index}`}
        label="Other Reason*"
        name={`explantedReasonNote-${index}`}
        disabled={isAssetFieldDisabled}
        value={assetFormData.dispositions[index].explantedReasonNote}
        placeholder="Other Reason"
        inputMode="text"
        handleChangeFormData={createDispositionsChangeHandler(index)}
        errorMessage={validationErrors?.explantedReasonNote}
        shrink
      />
    )

  const isSiteLocationEnabled = (index: number) =>
    (assetFormData.dispositions[index]?.finalDisposition === 'IMPLANTED' ||
      assetFormData.dispositions[index]?.finalDisposition === 'EXPLANTED') &&
    (assetFormData.dispositions[index]?.implantSite === 'Other' ||
      assetFormData.dispositions[index]?.implantSite === 'Mouth')

  const isManualAndExplanted = (index: number) =>
    assetFormData.dispositions[index]?.finalDisposition === 'EXPLANTED' &&
    isManualAddition

  const isExplanted = (index: number) =>
    assetFormData.dispositions[index]?.finalDisposition === 'EXPLANTED'

  return (
    <>
      <Helmet>
        <title>Result</title>
      </Helmet>

      {FormValidationAlertToasters()}

      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        px={1.5}
        pt={1.5}
        mb="56px"
        sx={{ overflowX: 'hidden' }}
      >
        <Box>
          <Button
            sx={{ mb: 2, width: 'fit' }}
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          {/* hidden field(s) necessary for form schema validation */}
          <input
            type="hidden"
            name="expirationDate"
            value={expirationDate ?? ''}
          />
          <Box>
            <Typography variant="h1">
              {isManualAddition ? 'Add product manually' : 'Captured product'}
            </Typography>

            <Paper className={styles.assetFound} sx={{ my: 2 }} elevation={0}>
              <AssetDetailsCard
                startComponent={
                  <Box className={styles.icon} sx={{ mr: 2 }}>
                    {isManualAddition ? (
                      <Search fontSize="large" />
                    ) : (
                      <QrCode fontSize="large" />
                    )}
                  </Box>
                }
                assetDetails={{
                  assetTitle: {
                    value: deviceDescription,
                  },
                  assetSizeText: {
                    value: sizeText,
                  },
                  assetTypeLabel: {
                    value: getAssetTypeLabel(
                      getAssetTypeCaps(mainIconFlowState.assetTypeLabel)
                    ),
                  },
                  assetManufacturer: {
                    value: company?.name || '',
                  },
                  deviceId: {
                    value: deviceId,
                  },
                  assetTrayId: {
                    value: assetFormData.assetTray,
                  },
                  modelNumber: {
                    value: versionModelNumber,
                  },
                  catalogNumber: {
                    value: catalogNumber,
                  },
                  lotNumber: {
                    value: lotBatch,
                  },
                  serialNumber: {
                    value: assetFormData.serialNumber ?? serialNumber,
                  },
                  expirationDate: {
                    value: expirationDate,
                  },
                  deviceCount: {
                    value: deviceCount,
                  },
                  usedCount: {
                    value: null,
                  },
                }}
                isExpireAccepted={false}
                assetType={getAssetTypeCaps(mainIconFlowState.assetTypeLabel)}
              />
            </Paper>

            <FormControl fullWidth>
              <Box display="flex" alignItems="center" gap={2}>
                <Box flexGrow="1">
                  <AutocompleteInput
                    id={'tray'}
                    label={'Inventory Location'}
                    name={'tray'}
                    placeholder="None"
                    value={assetFormData.assetTray ?? ''}
                    handleChangeFormData={handleChangeTray}
                    onTextInputChange={handleChangeTray}
                    options={surgery.assetTrays.filter(Boolean)}
                    disabled={isAssetFieldDisabled}
                    autoSelect={false}
                    autoHighlight={false}
                  />
                </Box>
                <Button
                  sx={{
                    mt: 1.5,
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={isAssetAddedToList}
                  onClick={() => {
                    setIsTrayScannerOpen('tray')
                    setUserCanScan(true)
                  }}
                  className="barcode-button"
                >
                  <BarcodeIcon
                    active
                    color={
                      !isAssetAddedToList
                        ? 'var(--primary)'
                        : 'var(--grayscale)'
                    }
                  />
                </Button>
              </Box>
            </FormControl>
            <FormControl fullWidth>
              <SelectInput
                id="asset-type"
                label="Product type"
                name="assetType"
                disabled={
                  isAssetFieldDisabled ||
                  matchedAssetGroup !== undefined ||
                  isRep
                }
                value={assetFormData?.assetType as string}
                handleChangeFormData={handleChangeAssetType}
                options={assetTypes}
              />
            </FormControl>
            {assetFormData.assetType
              ? Array.from({
                  length: isMultipackNonConsumable ? deviceCount : 1,
                }).map((_, index) => {
                  const disposition =
                    assetFormData.dispositions[index]?.finalDisposition || ''
                  const siteLocationRenderConditions = [
                    'EXPLANTED',
                    'IMPLANTED',
                  ]

                  return (
                    <Box
                      key={`disposition-${index}`}
                      sx={
                        isMultipackNonConsumable
                          ? {
                              mt: 2,
                              pl: 1.5,
                              mb: 4,
                              borderLeft: '2px solid #eee',
                            }
                          : {
                              mt: 0.5,
                            }
                      }
                    >
                      {isMultipackNonConsumable ? (
                        <Typography variant="h5" color="primary" mb={1}>
                          Disposition #
                          {isMultipackNonConsumable ? index + 1 : ''} of{' '}
                          {deviceCount}
                        </Typography>
                      ) : null}

                      {assetFormData.assetType === 'consumable' && (
                        <FormControl fullWidth>
                          <ConsumableModal
                            consumableModalOpen={consumableModalOpen}
                            deviceCount={deviceCount}
                            handleConsumableModalClose={
                              handleConsumableModalClose
                            }
                            index={index}
                            setConsumableModalOpen={setConsumableModalOpen}
                            createDispositionsChangeHandler={
                              createDispositionsChangeHandler
                            }
                            isAssetFieldDisabled={isAssetFieldDisabled}
                            assetFormData={assetFormData}
                            multipackItemsUsed={multipackItemsUsed}
                            handleSetMultipackItemsUsed={setMultipackItemsUsed}
                            isRfMultipackConsumable={isRfMultipackConsumable}
                            isSpongeConsumable={isSpongeConsumable}
                            isLoading={addSurgeryAssetScanMutation.loading}
                          />
                        </FormControl>
                      )}

                      {assetFormData.assetType !== 'consumable' && (
                        <FormControl fullWidth>
                          {assetFormData.assetType && (
                            <SelectInput
                              id={`final-disposition-${index}`}
                              label="Final disposition*"
                              name={`finalDisposition-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={
                                assetFormData.dispositions[index]
                                  ?.finalDisposition as string
                              }
                              handleChangeFormData={createDispositionsChangeHandler(
                                index
                              )}
                              options={finalDisposition}
                              sx={{ my: 1 }}
                              errorMessage={validationErrors?.finalDisposition}
                            />
                          )}
                          {disposition === 'IMPLANTED' && (
                            <AutocompleteInput
                              id={`implant-site-${index}`}
                              label="Implant site*"
                              name={`implantSite-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={
                                assetFormData.dispositions[index]
                                  ?.implantSite as string
                              }
                              handleChangeFormData={createDispositionsChangeHandler(
                                index
                              )}
                              options={implantSiteList}
                              errorMessage={validationErrors?.implantSite}
                            />
                          )}
                          {disposition === 'EXPLANTED' && (
                            <AutocompleteInput
                              id={`implant-site-${index}`}
                              label="Explant site*"
                              name={`implantSite-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={
                                assetFormData.dispositions[index]
                                  ?.implantSite as string
                              }
                              handleChangeFormData={createDispositionsChangeHandler(
                                index
                              )}
                              options={implantSiteList}
                              errorMessage={validationErrors?.implantSite}
                            />
                          )}
                          {siteLocationRenderConditions.includes(disposition) &&
                            renderSiteLocation(index)}
                          {disposition === 'IMPLANTED' &&
                            assetFormData.assetType === 'biological' && (
                              <>
                                <SelectInput
                                  id={`packaging-ok-${index}`}
                                  label="Packaging Intact"
                                  name={`packagingOk-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    (assetFormData.dispositions[index]
                                      ?.packagingOk as AssetGoodConditionCheck) ??
                                    getEnumKeyByKey(
                                      'NA',
                                      Object.entries(AssetGoodConditionCheck)
                                    )
                                  }
                                  handleChangeFormData={createDispositionsChangeHandler(
                                    index
                                  )}
                                  options={Object.entries(
                                    AssetGoodConditionCheck
                                  ).map(([key, val]) => {
                                    return {
                                      value: key,
                                      label: val,
                                    }
                                  })}
                                  sx={{ my: 1 }}
                                />

                                <SelectInput
                                  id={`temperature-ok-${index}`}
                                  label="Temperature Ok"
                                  name={`temperatureOk-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    (assetFormData.dispositions[index]
                                      ?.temperatureOk as AssetGoodConditionCheck) ??
                                    getEnumKeyByKey(
                                      'NA',
                                      Object.entries(AssetGoodConditionCheck)
                                    )
                                  }
                                  handleChangeFormData={createDispositionsChangeHandler(
                                    index
                                  )}
                                  options={Object.entries(
                                    AssetGoodConditionCheck
                                  ).map((elem) => {
                                    return {
                                      value: elem[0],
                                      label: elem[1],
                                    }
                                  })}
                                  sx={{ my: 1 }}
                                />

                                <SelectInput
                                  id={`sample-preparation-${index}`}
                                  label="Sample Preparation*"
                                  name={`samplePreparation-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    assetFormData.dispositions[index]
                                      ?.samplePreparation as SamplePreparationType
                                  }
                                  handleChangeFormData={createDispositionsChangeHandler(
                                    index
                                  )}
                                  options={Object.entries(
                                    SamplePreparationType
                                  ).map(([key, val]) => {
                                    return {
                                      value: key,
                                      label: val,
                                    }
                                  })}
                                  sx={{ my: 1 }}
                                  errorMessage={
                                    validationErrors?.samplePreparation
                                  }
                                />

                                {assetFormData.dispositions[index]
                                  ?.samplePreparation ===
                                  getEnumKeyByKey(
                                    'OTHER',
                                    Object.entries(SamplePreparationType)
                                  ) && (
                                  <TextInput
                                    id={`sample-preparation-note-${index}`}
                                    label="Sample Preparation Note*"
                                    name={`samplePreparationNote-${index}`}
                                    disabled={isAssetFieldDisabled}
                                    value={
                                      assetFormData.dispositions[index]
                                        .samplePreparationNote
                                    }
                                    placeholder={'Sample Preparation Note*'}
                                    inputMode="text"
                                    handleChangeFormData={createDispositionsChangeHandler(
                                      index
                                    )}
                                    errorMessage={
                                      validationErrors?.samplePreparationNote
                                    }
                                    shrink
                                  />
                                )}

                                <TextInput
                                  id={`implant-memo-${index}`}
                                  label="Scrub Tech / Note"
                                  name={`implantMemo-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    assetFormData.dispositions[index]
                                      .implantMemo
                                  }
                                  placeholder={'Note'}
                                  inputMode="text"
                                  handleChangeFormData={createDispositionsChangeHandler(
                                    index
                                  )}
                                  shrink={true}
                                />

                                <FormControl fullWidth>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <Box flexGrow="1">
                                      <AutocompleteInput
                                        id={'serialNumber'}
                                        label={'Serial Number'}
                                        name={'serialNumber'}
                                        placeholder="None"
                                        value={
                                          assetFormData.serialNumber ??
                                          serialNumber
                                        }
                                        handleChangeFormData={
                                          handleChangeSerialNumber
                                        }
                                        onTextInputChange={
                                          handleChangeSerialNumber
                                        }
                                        options={[]}
                                        disabled={isAssetFieldDisabled}
                                        autoSelect={false}
                                        autoHighlight={false}
                                      />
                                    </Box>

                                    <Button
                                      sx={{
                                        mt: 1.5,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={isAssetFieldDisabled}
                                      onClick={() => {
                                        setIsTrayScannerOpen('serial')
                                        setUserCanScan(true)
                                      }}
                                      className="barcode-button"
                                    >
                                      <BarcodeIcon
                                        active
                                        color={
                                          isAssetFieldDisabled
                                            ? 'var(--grayscale)'
                                            : 'var(--primary)'
                                        }
                                      />
                                    </Button>
                                  </Box>
                                </FormControl>
                              </>
                            )}

                          {isManualAndExplanted(index) && (
                            <SelectInput
                              disabled={isAssetFieldDisabled}
                              id={`explanted-reason-${index}`}
                              label="Explanted Reason*"
                              name={`explantedReason-${index}`}
                              value={
                                assetFormData.dispositions[index]
                                  ?.explantedReason as string
                              }
                              handleChangeFormData={createDispositionsChangeHandler(
                                index
                              )}
                              options={EXPLANTED_REASON_LIST}
                              sx={{ marginBottom: '8px' }}
                              errorMessage={validationErrors?.explantedReason}
                            />
                          )}

                          {isManualAndExplanted(index) &&
                            renderExplantedReasonNote(index)}

                          {disposition === 'WASTED' && (
                            <SelectInput
                              required={false}
                              id={`wasted-reason-${index}`}
                              label="Wasted reason*"
                              name={`wastedReason-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={
                                assetFormData.dispositions[index]
                                  ?.wastedReason as string
                              }
                              handleChangeFormData={createDispositionsChangeHandler(
                                index
                              )}
                              options={wastedReasons}
                              sx={{ marginBottom: '8px' }}
                              errorMessage={validationErrors?.wastedReason}
                            />
                          )}
                        </FormControl>
                      )}
                    </Box>
                  )
                })
              : null}
          </Box>

          <Box sx={{ mt: 2 }}>
            <MediaUpload
              disableActions={isAssetFieldDisabled}
              isLocal
              hasActions
              onLocalMediaFilesChange={onMediaFilesChange}
            />
          </Box>

          {isAssetAddedToList && (
            <Typography
              variant="h4"
              sx={{ mb: 1 }}
              className={styles.addedToList}
            >
              <Check sx={{ mr: 1 }} />
              Added to documented list
            </Typography>
          )}

          <Box sx={{ width: 0.9, mx: 'auto', mb: 2.5 }}>
            {!isAssetAddedToList && (
              <>
                <Typography
                  variant="h6"
                  className={styles.secondaryText}
                  sx={{ my: 1.5 }}
                >
                  Make sure that the information entered above is correct. Once
                  the product has been added to the product list, it cannot be
                  changed.
                </Typography>
                <LoadingButton
                  loading={addSurgeryAssetScanMutation.loading}
                  loadingText="Adding to documented list"
                  type="submit"
                >
                  Add to documented list
                </LoadingButton>
              </>
            )}
          </Box>
        </form>
      </Box>
    </>
  )
}

export default Result
