import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { DoNotDisturb } from '@mui/icons-material'
import { Typography } from '@mui/material'
import classNames from 'classnames'
import { ReactNode } from 'react'

import { ConfirmScrewRemovalModal } from 'components/organisms/ConfirmScrewRemovalModal/ConfirmScrewRemovalModal'
import ConfirmScrewLoadModal from '../../../../components/organisms/ConfirmScrewLoadModal/ConfirmScrewLoadModal'
import { LoadMultipleScrewsBox } from 'components/organisms/LoadMultipleScrewsBox/LoadMultipleScrewsBox'
import TrayPlatesArea from 'components/organisms/TrayPlatesArea/TrayPlatesArea'
import HoverCardComponent from 'components/molecules/HoverCard/HoverCard'

import getIsMobileOrTablet from 'lib/utils/getIsMobileOrTablet'
import { useSPDScan } from '../Scan/Scan.context'
import useTrayMap from './TrayMap.logic'
import { useSPD } from '../SPD.context'

import { BetterIDTrayScrew } from '../SPD.types'
import { TrayMapProps } from './TrayMap.types'
import './TrayMap.scss'

const MapWrapper = ({
  children,
  shouldWrap,
}: {
  children: JSX.Element | JSX.Element[] | ReactNode
  shouldWrap: boolean
}) => {
  return shouldWrap ? (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      pinch={{
        step: 10,
      }}
      wheel={{
        step: 10,
      }}
      smooth
      doubleClick={{
        disabled: true,
      }}
    >
      <TransformComponent
        wrapperClass="transform-component_wrapper"
        contentClass="transform-component_content"
      >
        {children}
      </TransformComponent>
    </TransformWrapper>
  ) : (
    <div className="transform-component_wrapper">
      <div className="transform-component_content">{children}</div>
    </div>
  )
}

const TrayMap = ({
  trayType = 'stryker screw caddy',
  loadWithUDIs,
  mapContent,
  onClickActions,
  isScrewEnabled,
  isScrewImplanted,
  isScrewWasted,
  handlePlateUsage,
  getPlateCount,
  disablePlateIncrease = false,
}: TrayMapProps) => {
  const { selectedScrew, selectedScrews, screwLoadModalOpen } = useSPD()
  const {
    isScrewLoaded,
    screwAreas,
    findScrew,
    handleScrewSelection,
    handleCancelSelection,
    isConfirmScrewRemovalModalOpen,
    handleConfirmScrewRemovalModalClose,
    handleRemoveScrew,
    handlePlateCountChange,
    getInitialPlateCount,
  } = useTrayMap({ trayType, loadWithUDIs, mapContent })
  const { isLoadMultipleBoxVisible } = useSPDScan()

  return (
    <div className="tray-visualization_container">
      <LoadMultipleScrewsBox open={isLoadMultipleBoxVisible} />

      <div
        className={classNames('tray-visualization', {
          'with-plates': trayType !== 'stryker screw caddy',
          'is-mobile': getIsMobileOrTablet(),
        })}
      >
        {trayType === 'stryker screw caddy' && (
          <div className="header">
            <p className="title measurements">2.0 MP</p>

            <p className="title measurements">SCREWS</p>
          </div>
        )}

        <MapWrapper shouldWrap={!onClickActions}>
          {trayType !== 'stryker screw caddy' && (
            <TrayPlatesArea
              handlePlateCountChange={
                handlePlateUsage ? handlePlateUsage : handlePlateCountChange
              }
              getInitialPlateCount={getPlateCount || getInitialPlateCount}
              disableIncrease={disablePlateIncrease}
            />
          )}

          <div className="screw-area_container">
            {screwAreas.map((area: any, areaIndex: number) => {
              const areaClassNames = `${
                area.label.includes('Drilling')
                  ? 'drilling'
                  : area.label.includes('Emergency')
                  ? 'emergency'
                  : 'bone'
              } ${area.label.includes('top') ? 'top' : 'bottom'}`

              return (
                <div className={`screw-area ${areaClassNames}`} key={areaIndex}>
                  <p className="label">{area.label}</p>

                  <div className="column-sizes_wrapper">
                    {Array.from({ length: area.num_columns }, (_, colIndex) => (
                      <div className="column-size_container" key={colIndex}>
                        <p className="size">{area.column_labels[colIndex]}</p>
                      </div>
                    ))}
                  </div>
                  {Array.from({ length: area.num_rows }, (_, rowIndex) => (
                    <div className="screws-row" key={rowIndex}>
                      {Array.from(
                        { length: area.num_columns },
                        (_, colIndex) => {
                          const isSelected =
                            (selectedScrew &&
                              selectedScrew.label === area.label &&
                              selectedScrew.row === rowIndex + 1 &&
                              selectedScrew.x === colIndex &&
                              selectedScrew.column ===
                                area.column_labels[colIndex]) ||
                            selectedScrews.some(
                              (screw) =>
                                screw.label === area.label &&
                                screw.row === rowIndex + 1 &&
                                screw.x === colIndex &&
                                screw.column === area.column_labels[colIndex]
                            )

                          const isDisabled =
                            isScrewEnabled &&
                            !isScrewEnabled(area.label, rowIndex + 1, colIndex)

                          const isImplanted =
                            isScrewImplanted &&
                            isScrewImplanted(area.label, rowIndex + 1, colIndex)

                          const isWasted =
                            isScrewWasted &&
                            isScrewWasted(area.label, rowIndex + 1, colIndex)

                          const isLoaded = isScrewEnabled
                            ? isScrewEnabled(area.label, rowIndex + 1, colIndex)
                            : isScrewLoaded(area.label, rowIndex + 1, colIndex)

                          const screwClassNames = `${areaClassNames} ${
                            isWasted ? 'wasted' : ''
                          } ${isImplanted ? 'implanted' : ''} ${
                            isSelected ? 'selected' : ''
                          } ${isLoaded ? 'loaded' : ''} ${
                            isDisabled ? 'disabled' : ''
                          }`

                          const options = !isLoaded ? { open: false } : {}

                          const screwData = findScrew(
                            area.label,
                            rowIndex + 1,
                            colIndex
                          ) as BetterIDTrayScrew

                          return (
                            <HoverCardComponent
                              key={colIndex}
                              {...options}
                              content={
                                <>
                                  {isLoaded && (
                                    <div className="screw-data_container">
                                      <Typography
                                        variant="h2"
                                        className="description"
                                      >
                                        {screwData.deviceDescription}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        className="details"
                                      >
                                        Company: {screwData.company?.name}
                                      </Typography>
                                    </div>
                                  )}
                                </>
                              }
                            >
                              <div
                                className={`screw ${screwClassNames}`}
                                onClick={() => {
                                  if (isDisabled) {
                                    return
                                  }

                                  onClickActions?.screw
                                    ? onClickActions.screw(
                                        area.label,
                                        rowIndex + 1,
                                        area.column_labels[colIndex],
                                        colIndex
                                      )
                                    : handleScrewSelection(
                                        area.label,
                                        rowIndex + 1,
                                        area.column_labels[colIndex],
                                        colIndex
                                      )
                                }}
                              >
                                {isDisabled && (
                                  <DoNotDisturb className="not-allowed_icon" />
                                )}
                              </div>
                            </HoverCardComponent>
                          )
                        }
                      )}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </MapWrapper>

        <ConfirmScrewLoadModal
          open={screwLoadModalOpen}
          onClose={handleCancelSelection}
        />

        {selectedScrew && (
          <ConfirmScrewRemovalModal
            open={isConfirmScrewRemovalModalOpen}
            onClose={handleConfirmScrewRemovalModalClose}
            screw={
              findScrew(
                selectedScrew.label,
                selectedScrew.row,
                selectedScrew.x
              ) as BetterIDTrayScrew
            }
            handleRemoveScrew={() =>
              handleRemoveScrew(
                findScrew(
                  selectedScrew.label,
                  selectedScrew.row,
                  selectedScrew.x
                ) as BetterIDTrayScrew
              )
            }
          />
        )}
      </div>
    </div>
  )
}

export default TrayMap
