import { useState, useMemo } from 'react'
import { useUser } from 'app/User'
import { RfSpongeCountPanel } from 'components/Consumables/RfSpongeCountPanel'
import {
  useDeleteSurgeryAssetsByIds,
  useDeleteConsumableAssetsByIds,
} from 'lib/apollo/hooks'
import {
  deriveAssetDetailsFromAssetData,
  groupScanStatuses,
  getCountFromScans,
} from 'lib/utils/data'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { DispositionedAssetDetails } from '../DispositionedAssetDetails/DispositionedAssetDetails'
import { StoredAssetProps } from './StoredAsset.types'
import { EditAssetButton } from '../EditAssetButton/EditAssetButton'
import { useEditDocumentedListContext } from 'lib/context/EditDocumentedListContext/EditDocumentedListContext'
import { isDatePast } from 'lib/utils/isDatePast'

export const useStoredAssetLogic = ({
  assetData,
  surgeryId,
  isProcedureSubmitted,
  groupedAssetData,
}: StoredAssetProps) => {
  // hooks
  const { user, isNurseAdmin, isNurse } = useUser()
  const {
    isSavingEditedAsset,
    mutationStatus,
    assetGroupId,
    consumableAssetGroup,
    showStatusBar,
  } = useEditDocumentedListContext()

  const assetDetails = deriveAssetDetailsFromAssetData(assetData)
  const {
    assetDescription,
    sizeText,
    deviceCount,
    scans,
    secondaryDeviceIdType,
    accurateDeviceCount,
    deviceCountCorrectiveActionTaken,
    expirationDate,
    serialNumber,
    catalogNumber,
    lotBatch,
    deviceId,
    assetTray,
    versionModelNumber,
    isManualAddition,
    _id,
    bidAssetId,
  } = assetDetails

  const [deleteSurgeryAssetsByIds, deleteSurgeryAssetsByIdsMutation] =
    useDeleteSurgeryAssetsByIds({ surgeryId })
  const [deleteConsumableAssetsByIds, deleteConsumableAssetsByIdsMutation] =
    useDeleteConsumableAssetsByIds({
      surgeryId,
    })

  // states
  const [toBeDeleted, setToBeDeleted] = useState<string[]>([])
  const [reviewingMedia, setReviewingMedia] = useState(false)
  const [mediaAssetId] = useState(assetData?.scans[0]._id)

  // constants
  const groupedStatuses = useMemo(() => groupScanStatuses(scans), [scans])
  const assetType = assetData.scans[0].assetType
  const fileNames = scans
    ?.flatMap((scan) => scan.media)
    .filter((media) => media !== undefined) as string[]

  const {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isMultipackHardware,
    isSpongeConsumable,
  } = parseAssetIdentifiers({
    deviceDescription: assetDescription,
    deviceCount,
    idType: secondaryDeviceIdType,
    assetType: assetType,
  })

  const consumableScans = useMemo(
    () => scans.filter((scan) => scan.assetType === 'consumable'),
    [scans]
  )
  const singleConsumablesCount = assetData.total

  // This is a temporary fix. The error is from mgat-services surgery.js line 1250.
  const multipackConsumablesCount = useMemo(
    // () => assetData.packsUsed,
    () => assetData.scans.length,
    // [assetData.packsUsed]
    [assetData.scans.length]
  )

  const associatedScans = useMemo(
    () => scans.filter((scan) => scan.implantStatus === 'ASSOCIATED_ASSET'),
    [scans]
  )
  const associatedCount = getCountFromScans(associatedScans)

  const hasApproved = useMemo(
    () => 'APPROVED' in groupedStatuses,
    [groupedStatuses]
  )

  const isApproved = useMemo(
    () => hasApproved && Object.keys(groupedStatuses).length === 1,
    [groupedStatuses, hasApproved]
  )

  const status = assetData.scans[0].status
  const isRepScan = Boolean(status.bidCompanyId)
  const canDelete =
    !isProcedureSubmitted &&
    !isApproved &&
    (isNurse || isNurseAdmin || (isRepScan && status.userId === user?.id))

  const isConsumable = assetType === 'consumable'
  const packCount = isMultipackHardware ? assetData.packsUsed ?? 1 : 1
  let unusedCount = isMultipackHardware
    ? packCount * deviceCount - scans.length
    : 0

  const explantedScansCount = groupedAssetData?.explanted[0]?.scans?.length ?? 0
  unusedCount = unusedCount - explantedScansCount

  const isDTMScrew = scans.some(
    (scan) =>
      scan.isDTMScrew &&
      assetData.scans.some((assetScan) => assetScan._id === scan._id)
  )

  const isExpired = isDatePast({ date: expirationDate })?.isDateExpired

  const renderStatusBar =
    showStatusBar &&
    (consumableAssetGroup?._id === assetData._id ||
      assetGroupId === assetData._id)

  // handles
  const handleDelete = async (deleteCount: number) => {
    if (isConsumable) {
      try {
        const result = await deleteConsumableAssetsByIds({
          variables: {
            count: deleteCount,
            assetIds: toBeDeleted.slice(0, deleteCount),
            isMultipack: isMultipackConsumable,
          },
        })

        if (result.data?.deleteConsumableAssetsByIds?.success) {
          setToBeDeleted([])
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      await deleteSurgeryAssetsByIds({
        variables: {
          surgeryId,
          assetIds: toBeDeleted.slice(0, deleteCount),
        },
      }).finally(() => {
        setToBeDeleted([])
      })
    }
  }

  const consumablesCountLabel = () => {
    const spongeCount = multipackConsumablesCount * deviceCount
    const pack =
      multipackConsumablesCount > 1
        ? isRfMultipackConsumable || isSpongeConsumable || isMultipackHardware
          ? 'Packs'
          : 'Pack'
        : 'pack'

    const label = `${pack} Used`

    const handleDelete = canDelete
      ? () => {
          const ids = consumableScans.map((scan) => scan._id)
          setToBeDeleted(ids)
        }
      : undefined

    if (isRfMultipackConsumable && multipackConsumablesCount > 0) {
      return (
        <RfSpongeCountPanel
          label={label}
          spongeCount={spongeCount}
          value={multipackConsumablesCount}
          isProcedureSubmitted={isProcedureSubmitted}
          handleDelete={handleDelete}
          isLoading={
            deleteConsumableAssetsByIdsMutation.loading ||
            isSavingEditedAsset(_id)
          }
          isManualAddition={isManualAddition}
          assetGroup={assetData}
          assetTray={assetTray}
          surgeryId={surgeryId}
        />
      )
    } else if (isSpongeConsumable && multipackConsumablesCount > 0) {
      return (
        <DispositionedAssetDetails
          value={multipackConsumablesCount}
          label={label}
          additionalLabels={[`Total: ${spongeCount} sponges`]}
          handleDelete={handleDelete}
          isLoading={deleteConsumableAssetsByIdsMutation.loading}
          editComponent={
            <EditAssetButton
              isManualAddition={isManualAddition}
              bidAssetId={bidAssetId}
              assetgroupId={_id}
              isProcedureSubmitted={isProcedureSubmitted}
              surgeryId={surgeryId}
              assetTray={assetTray}
              consumableAssetGroup={assetData}
              isMultipack={isSpongeConsumable}
            />
          }
        />
      )
    } else if (isConsumable && singleConsumablesCount > 0) {
      return (
        <DispositionedAssetDetails
          value={singleConsumablesCount}
          label="Used"
          handleDelete={handleDelete}
          isLoading={
            deleteConsumableAssetsByIdsMutation.loading ||
            isSavingEditedAsset(_id)
          }
          editComponent={
            <EditAssetButton
              singleConsumablesCount={singleConsumablesCount}
              isManualAddition={isManualAddition}
              bidAssetId={bidAssetId}
              assetgroupId={_id}
              isProcedureSubmitted={isProcedureSubmitted}
              surgeryId={surgeryId}
              assetTray={assetTray}
              consumableAssetGroup={assetData}
              isMultipack={isMultipackConsumable}
            />
          }
        />
      )
    } else {
      return null
    }
  }

  return {
    assetData,
    assetType,
    assetTray,
    associatedCount,
    canDelete,
    singleConsumablesCount,
    consumablesCountLabel,
    deleteSurgeryAssetsByIdsMutation,
    deviceCountCorrectiveActionTaken,
    expirationDate,
    groupedStatuses,
    isConsumable,
    isDTMScrew,
    isMultipackConsumable,
    isMultipackHardware,
    isRfMultipackConsumable,
    isSpongeConsumable,
    mediaAssetId,
    reviewingMedia,
    scans,
    serialNumber,
    setReviewingMedia,
    sizeText,
    toBeDeleted,
    unusedCount,
    versionModelNumber,
    handleDelete,
    deleteConsumableAssetsByIdsMutation,
    setToBeDeleted,
    isApproved,
    accurateDeviceCount,
    assetDescription,
    deviceId,
    catalogNumber,
    lotBatch,
    deviceCount,
    associatedScans,
    hasApproved,
    fileNames,
    mutationStatus,
    isExpired,
    renderStatusBar,
  }
}
