import { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Option } from 'common/types'
import Tooltip from 'components/molecules/Tooltip/Tooltip'
import { AutocompleteInputLogicProps } from './AutocompleteInput.types'

export const useAutocompleteInputLogic = ({
  disabled,
  required,
  variant,
  sx,
  value,
  toolTip,
  getOptionDisabled,
  handleChangeFormData,
  name,
  error,
}: AutocompleteInputLogicProps) => {
  const [isFilled, setIsFilled] = useState(false)

  const autocompleteStyles = {
    mt: 2.5,
    mb: (required && !isFilled) || error ? -0.1 : 2,
    backgroundColor: variant === 'outlined' ? 'white' : 'transparent',
    '& .MuiButtonBase-root, & .MuiIconButton-root, & .MuiAutocomplete-popupIndicator':
      {
        color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'primary.main',
      },
    ...(error && {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'red',
        },
      },
    }),
    ...sx,
  }

  useEffect(() => {
    if (typeof value === 'string') {
      setIsFilled(value !== '')
    } else if (
      value &&
      typeof value === 'object' &&
      'id' in value &&
      'name' in value
    ) {
      setIsFilled(Boolean(value.id) && Boolean(value.name))
    } else {
      setIsFilled(false)
    }
  }, [value, setIsFilled])

  const renderItem = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option | string
  ) => {
    const opt = typeof option === 'object' ? option.name : option
    const isDisabled = getOptionDisabled ? getOptionDisabled(opt) : false

    const optionElement = (
      <MenuItem
        {...props}
        onClick={(event) => handleClick(event, isDisabled)}
        disabled={isDisabled}
      >
        {option}
      </MenuItem>
    )

    return toolTip && toolTip(opt) ? (
      <Tooltip key={opt} tooltipContent={toolTip(opt)} side="bottom">
        <span>{optionElement}</span>
      </Tooltip>
    ) : (
      optionElement
    )
  }

  const handleClick = (event: React.MouseEvent, isDisabled: boolean) => {
    if (isDisabled) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      handleChangeFormData({
        target: {
          name: name ?? '',
          value: event.currentTarget.textContent ?? '',
        },
      })
    }
  }

  return {
    isFilled,
    autocompleteStyles,
    renderItem,
  }
}
