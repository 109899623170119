import { useMemo, useState } from 'react'
import { useGetSurgeriesQuery } from 'lib/apollo/hooks'
import dayjs from 'lib/dayjs'
import { Option, Surgery } from 'common/types'
import { useDisablePolling } from 'lib/useDisablePolling'
import { useProcedureListTableLogic } from 'components/ProcedureList/ProcedureListTable.logic'
import { useCreateApolloClient } from 'lib/apollo'
import { formatSurgeryProviderName } from 'lib/utils/formatSurgeryProviderName'
import { useAddTrayContext } from 'views/TrayManagementLayout/AddTray/AddTray.context'

const TODAY = dayjs().toDate()
const ONE_MONTH_FROM_NOW = dayjs().add(1, 'month').toDate()

/**
 * Hook to manage the logic for tray case details.
 * @returns {object} Object containing tray case details logic.
 */
const useTrayCaseDetailsLogic = () => {
  const disablePolling = useDisablePolling()
  const { client } = useCreateApolloClient()
  const { traySurgeryId, setTraySurgeryId, setTraySurgery } =
    useAddTrayContext()

  const apolloClient = useMemo(() => client, [])

  const [beforeDate, setBeforeDate] = useState<Date>(ONE_MONTH_FROM_NOW)
  const [afterDate, setAfterDate] = useState<Date>(TODAY)
  const [surgeonFilter, setSurgeonFilter] = useState<string | undefined>(
    undefined
  )

  const { data, loading, error } = useGetSurgeriesQuery(
    {
      before: beforeDate,
      after: afterDate,
      status: undefined,
      limit: 0,
      isFromDTM: true,
    },
    {
      disablePolling,
      client: apolloClient,
    }
  )

  const surgeryList: Surgery[] = data ? data.getSurgeries?.results : []

  const {
    isNurse,
    caseInformation,
    sortByRoom: surgeries = [],
  } = useProcedureListTableLogic({ events: surgeryList })

  /**
   * Handles changes to the before date.
   * @param {Date} date - The new before date.
   */
  const handleBeforeDateChange = (date: Date) => {
    setBeforeDate(dayjs(date).endOf('day').toDate())
  }

  /**
   * Handles changes to the after date.
   * @param {Date} date - The new after date.
   */
  const handleAfterDateChange = (date: Date) => {
    setAfterDate(dayjs(date).startOf('day').toDate())
  }

  /**
   * Handles changes to the surgeon filter.
   * @param {Option} value - The selected surgeon option.
   */
  const handleSurgeonFilterChange = (value: Option) => {
    setSurgeonFilter(value.name)
  }

  /**
   * Clears the surgeon filter.
   */
  const handleClearSurgeonFilter = () => {
    setSurgeonFilter(undefined)
  }

  /**
   * Handles changes to the selected tray surgery.
   * @param {Surgery} surgery - The selected surgery.
   */
  const handleTraySurgeryChange = (surgery: Surgery) => {
    if (surgery._id === traySurgeryId) {
      setTraySurgeryId('')
      return
    }

    setTraySurgeryId(surgery._id)
    setTraySurgery(surgery)
  }

  /**
   * Checks if a surgery is selected.
   * @param {Surgery['_id']} surgeryId - The ID of the surgery to check.
   * @returns {boolean} True if the surgery is selected, false otherwise.
   */
  const isSurgerySelected = (surgeryId: Surgery['_id']) =>
    surgeryId === traySurgeryId

  /**
   * Generates surgeon options from the list of surgeries.
   * @param {Surgery[]} surgeries - The list of surgeries.
   * @returns {Option[]} The list of surgeon options.
   */
  const generateSurgeonOptions = (surgeries: Surgery[]): Option[] => {
    const surgeonSet = new Set<string>()
    return surgeries
      ? surgeries
          .map((surgery) => {
            const name = formatSurgeryProviderName(surgery)
            if (!surgeonSet.has(name)) {
              surgeonSet.add(name)
              return { name }
            }
            return null
          })
          .filter((option): option is Option => option !== null)
      : []
  }

  const surgeonOptions = useMemo(
    () => generateSurgeonOptions(surgeries),
    [surgeries]
  )

  /**
   * Filters surgeries by the surgeon filter.
   * @param {Surgery[]} surgeries - The list of surgeries.
   * @param {string | undefined} surgeonFilter - The surgeon filter.
   * @returns {Surgery[]} The filtered list of surgeries.
   */
  const filterSurgeriesBySurgeon = (
    surgeries: Surgery[],
    surgeonFilter: string | undefined
  ): Surgery[] => {
    return surgeries
      ? surgeries.filter((surgery) =>
          surgeonFilter
            ? formatSurgeryProviderName(surgery).includes(surgeonFilter)
            : true
        )
      : []
  }

  const filteredSurgeries = useMemo(
    () => filterSurgeriesBySurgeon(surgeries, surgeonFilter),
    [surgeries, surgeonFilter]
  )

  return {
    surgeries,
    loading,
    error,
    isNurse,
    caseInformation,
    afterDate,
    beforeDate,
    handleBeforeDateChange,
    handleAfterDateChange,
    surgeonOptions,
    surgeonFilter,
    handleSurgeonFilterChange,
    handleClearSurgeonFilter,
    filteredSurgeries,
    handleTraySurgeryChange,
    isSurgerySelected,
  }
}

export default useTrayCaseDetailsLogic
