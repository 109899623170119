import React, { useEffect, useRef } from 'react'
import { useAuth0 } from 'app/Auth'
import { Box } from '@mui/material'

const Analytics: React.FC = () => {
  const analyticsUrl =
    process.env.REACT_APP_ANALYTICS_URL || 'https://analytics-stg.medgeo.com'
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { getAccessToken } = useAuth0()

  useEffect(() => {
    const sendApiTokenToIframe = async () => {
      try {
        const token = await getAccessToken()
        if (iframeRef.current && iframeRef.current.contentWindow) {
          iframeRef.current.src = analyticsUrl
          iframeRef.current.onload = () => {
            setTimeout(() => {
              iframeRef.current?.contentWindow?.postMessage(
                { type: 'SET_TOKEN', token },
                analyticsUrl
              )
            }, 200)
          }
        }
      } catch (error) {
        console.error('Error getting access token:', error)
      }
    }

    sendApiTokenToIframe()
  }, [analyticsUrl, getAccessToken])

  return (
    <Box
      sx={{ width: '100%', height: 'calc(100vh - 64px)', overflow: 'hidden' }}
    >
      <iframe
        ref={iframeRef}
        title="Analytics Dashboard"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          overflow: 'hidden',
        }}
      />
    </Box>
  )
}

export default Analytics
