import { useEffect, useState } from 'react'

// Services
import {
  useCreateBetterIdProductEmail,
  useDeleteBetterIdProductEmail,
  useSendProductRegistrationEmailsMutation,
  useUpdateBetterIdProductEmail,
} from 'lib/apollo/hooks'
import { useUser } from 'app/User'

// Types
import { ProductEmailDetails } from 'common/types'
import {
  IDeleteEmail,
  IEditEmail,
  INewEmail,
  ProductRegistrationItemProps,
} from '../ProductRegistration.types'

// Utils
import { useIsMobile } from 'lib/utils/mediaQueries'

// Other
import { z } from 'zod'
import toast from 'react-hot-toast'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useProductItemLogic = ({
  product,
  scan,
  surgery,
  productsIds,
}: ProductRegistrationItemProps) => {
  const flags = useFlags()
  const { user } = useUser()
  const isMobile = useIsMobile()
  const [emails, setEmails] = useState<ProductEmailDetails[]>([])
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [addNewEmail, setAddNewEmail] = useState(false)
  const [newEmail, setNewEmail] = useState<INewEmail>()
  const [sentEmail, setSentEmail] = useState<string>()
  const [isEditing, setIsEditing] = useState<IEditEmail>()
  const [isDeleting, setIsDeleting] = useState<IDeleteEmail>()

  // Services
  const [sendProductRegEmail, { loading: isLoadingSendingEmail }] =
    useSendProductRegistrationEmailsMutation(productsIds)
  const [createProductEmail, { loading: isCreatingProductEmail }] =
    useCreateBetterIdProductEmail(productsIds)
  const [updateProductEmail, { loading: isUpdatingProductEmail }] =
    useUpdateBetterIdProductEmail(productsIds)
  const [deleteProductEmail, { loading: isDeletingProductEmail }] =
    useDeleteBetterIdProductEmail(productsIds)

  const rawSentEmails =
    surgery.implantRecordRegistration?.flatMap((item) =>
      item.emails.map((email) => ({
        email,
        bidAssetId: item.bidAssetId,
      }))
    ) || []
  const sentEmails = rawSentEmails
    .filter((item) => item.bidAssetId === scan.bidAssetId)
    .map((item) => item.email)

  const emailExists =
    sentEmails.includes(newEmail?.email as string) ||
    emails.some((email) => email.email === newEmail?.email)
  const productId = product.productEmail?.productId as number
  const emailSchema = z.string().email()
  const disableSave =
    !emailSchema.safeParse(newEmail?.email).success ||
    !emailSchema.safeParse(newEmail?.confirmEmail).success ||
    newEmail?.email !== newEmail?.confirmEmail ||
    emailExists
  const hasEmails = (product.productEmail?.emails.length as number) > 0
  const hasActions = flags?.allowActionsInProductsEmailsRegistration

  // Handlers
  const handleCreateProductEmail = () => {
    if (!newEmail?.email || !newEmail?.confirmEmail || disableSave) return
    createProductEmail({
      variables: {
        email: newEmail.email,
        productId: productId,
      },
    }).then((res) => {
      setAddNewEmail(false)
      setNewEmail(undefined)
      if (res.data?.betterIdCreateProductEmail.message) {
        toast.success(res.data?.betterIdCreateProductEmail.message)
      }
    })
  }

  const handleUpdateProductEmail = (email: string, emailId: number) => {
    updateProductEmail({
      variables: {
        email,
        emailId,
      },
    }).then((res) => {
      setIsEditing({ open: false })
      if (res.data?.betterIdUpdateProductEmail.message) {
        toast.success(res.data?.betterIdUpdateProductEmail.message)
      }
    })
  }

  const handleDeleteProductEmail = (productEmailId: number) => {
    deleteProductEmail({
      variables: {
        productEmailId,
      },
    }).then((res) => {
      setIsDeleting({ open: false })
      if (res.data?.betterIdDeleteProductEmail.message) {
        toast.success(res.data?.betterIdDeleteProductEmail.message)
      }
    })
  }

  const handleSendProductRegistrationEmail = (email?: string) => {
    setSentEmail(email)
    sendProductRegEmail({
      variables: {
        registrationInputs: [
          {
            surgeryId: surgery._id,
            companyName: product.company,
            userFirstName: user?.firstName,
            userLastName: user?.lastName,
            emailAddress: email || sentEmail,
            scanId: scan?._id ?? null,
            bidAssetId: scan.bidAssetId,
          },
        ],
      },
    })
      .then((res) => {
        if (res.data?.sendProductRegistrationEmails.success) {
          toast.success(`Successfully sent product registration to ${email}`)
        } else {
          setErrorDialogOpen(true)
        }
      })
      .catch(() => {
        setErrorDialogOpen(true)
      })
  }

  useEffect(() => {
    if (!product.productEmail?.emails) return
    setEmails([...(product.productEmail?.emails || [])])
  }, [product])

  return {
    emails,
    newEmail,
    isMobile,
    sentEmail,
    isEditing,
    hasEmails,
    sentEmails,
    isDeleting,
    hasActions,
    disableSave,
    addNewEmail,
    emailExists,
    errorDialogOpen,
    isLoadingSendingEmail,
    isUpdatingProductEmail,
    isCreatingProductEmail,
    isDeletingProductEmail,
    setNewEmail,
    setIsEditing,
    setIsDeleting,
    setAddNewEmail,
    setErrorDialogOpen,
    handleDeleteProductEmail,
    handleCreateProductEmail,
    handleUpdateProductEmail,
    handleSendProductRegistrationEmail,
  }
}
export default useProductItemLogic
