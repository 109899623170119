import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { useCompanySearchQuery } from 'lib/apollo/hooks'
import { BetterIdCompany, ManualInputFormData } from 'common/types'
import { TRepSignUpFormData } from './AddRepModal/RepSignupForm/repSignupForm.types'
import { useState, useEffect, ChangeEvent } from 'react'

interface CompanySearchBarProps {
  handleChangeFormData: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  manualAssetInputFormData: ManualInputFormData | TRepSignUpFormData
  variant?: 'standard' | 'outlined'
  disabled?: boolean
  required?: boolean
  error?: boolean
  helperText?: string
  mandatory?: boolean
}

export const CompanySearchBar: React.FC<CompanySearchBarProps> = ({
  handleChangeFormData,
  manualAssetInputFormData,
  variant,
  disabled = false,
  required = true,
  error,
  helperText,
  mandatory = false,
}) => {
  const [companyNameInput, setCompanyNameInput] = useState<string>(
    manualAssetInputFormData?.companyName
  )

  const { data, loading } = useCompanySearchQuery(companyNameInput)
  const companiesList = (
    data?.companySearch?.map((company: BetterIdCompany) => company.name) ?? []
  ).slice(0, 100)

  const handleCompanyNameInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCompanyNameInput(e.target.value)
  }

  useEffect(() => {
    setCompanyNameInput(manualAssetInputFormData.companyName)
  }, [manualAssetInputFormData.companyName])

  return (
    <AutocompleteInput
      id="companyName"
      label={mandatory ? 'Company Name*' : 'Company Name'}
      name="companyName"
      variant={variant}
      value={manualAssetInputFormData.companyName}
      handleChangeFormData={(e) => {
        handleChangeFormData(
          e as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        )
      }}
      onTextInputChange={(e) =>
        handleCompanyNameInputChange(
          e as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        )
      }
      options={companiesList}
      autoSelect={false}
      isLoading={loading}
      required={required}
      sx={{ my: 0 }}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  )
}
