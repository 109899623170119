import { Route, Routes } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'
import { Helmet } from 'react-helmet'

import TrayManagement from './TrayManagement/TrayManagement'
import FloatingBar from 'components/FloatingBar/FloatingBar'
import TrayDetails from './TrayDetails/TrayDetails'
import TrayApproval from './TrayApproval/TrayApproval'

import useTrayManagementLayoutLogic from './TrayManagementLayout.logic'
import { AssignedDigitalTraysProvider } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import { CaptureTrayContextProvider } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'

import './TrayManagementLayout.scss'
import AddTray from './AddTray/AddTray'
import { TrayConfigurationProvider } from 'views/TrayConfiguration/TrayConfiguration.context'
import { InventorySheetsProvider } from './AddTray/InventorySheets/InventorySheets.context'
import { AddTrayProvider } from './AddTray/AddTray.context'
import SubTrayDetails from 'views/SubTrayDetails/SubTrayDetails'

const TrayManagementLayout = () => {
  const {
    snackbarOpen,
    snackbarIcon,
    snackbarMessage,
    snackbarState,
    handleSnackbarClose,
  } = useTrayManagementLayoutLogic()

  return (
    <div className="tray-management-layout_wrapper">
      <Helmet>
        <title>Tray Management</title>
      </Helmet>

      <FloatingBar />

      <Routes>
        <Route
          path="/"
          element={
            <AssignedDigitalTraysProvider>
              <CaptureTrayContextProvider>
                <TrayManagement />
              </CaptureTrayContextProvider>
            </AssignedDigitalTraysProvider>
          }
        />
        <Route
          path="/add-tray/*"
          element={
            <TrayConfigurationProvider>
              <AssignedDigitalTraysProvider>
                <AddTrayProvider>
                  <InventorySheetsProvider>
                    <AddTray />
                  </InventorySheetsProvider>
                </AddTrayProvider>
              </AssignedDigitalTraysProvider>
            </TrayConfigurationProvider>
          }
        />
        <Route path="/:trayId" element={<TrayDetails />} />
        <Route path="/:trayId/:subTrayItemId" element={<SubTrayDetails />} />
        <Route
          path="/approve/:subTrayItemId"
          element={<TrayApproval isReviewing={true} />}
        />
        <Route
          path="/edit/:trayId/:subTrayItemId"
          element={<TrayApproval isReviewing={false} />}
        />
      </Routes>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default TrayManagementLayout
