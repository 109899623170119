import {
  ImplantReportInputs,
  ImplantReportStatus,
  ImplantReportType,
} from 'common/types'
import { useEffect, useState } from 'react'
import { useSendImplantReportEmailMutation } from 'lib/apollo/hooks'
import {
  ImplantReportInputValue,
  ImplantReportSenderModalLogicProps,
} from '../ImplantReportSender.types'
import { z } from 'zod'

export const useImplantReportSenderModalLogic = ({
  assetIds,
  surgeryId,
  implantReportStatus,
  implantReportInputs,
  setImplantReportInputs,
  unformatEnumValue,
  setMutationSuccess,
  setErrorMessage,
  setInputModalOpen,
}: ImplantReportSenderModalLogicProps) => {
  // Hooks
  const [sendImplantReport, sendImplantReportMutation] =
    useSendImplantReportEmailMutation([implantReportInputs])

  // States
  const [reportAlreadySentModal, setReportAlreadySentModal] =
    useState<boolean>(false)
  const [extractionValues, setExtractionValues] = useState<
    Array<string | number>
  >([''])

  // Constants
  const reportAlreadySent = (option: keyof typeof ImplantReportType) => {
    const enumType = unformatEnumValue(option)
    return (implantReportStatus as ImplantReportStatus[]).some(
      (status) => status.type === enumType && status.isSent
    )
  }

  const doNotRenderSubmitOverride =
    assetIds.length === 0 &&
    implantReportInputs.reportType &&
    ['FIRST_STAGE', 'SINGLE', 'SECOND_STAGE'].includes(
      implantReportInputs.reportType
    )

  // Handlers
  const handleChangeFormData = <Key extends keyof ImplantReportInputs>(
    reportType: Key,
    value: ImplantReportInputValue<Key>
  ) => {
    setValidationErrors(null)
    setImplantReportInputs((prevState) => ({
      ...prevState,
      reportInputs: {
        ...prevState.reportInputs,
        [reportType]: value,
      },
    }))
  }

  const handleAddExtractionInput = () => {
    setExtractionValues([...extractionValues, ''])
  }

  const handleChangeExtractionInput = (index: number, value: string) => {
    setValidationErrors(null)
    if (value.length <= 4) {
      const newValues = [...extractionValues]
      newValues[index] = value
      setExtractionValues(newValues)
      handleChangeFormData('extractionValues', newValues)
    }
  }

  const implantReportSchema = z.object({
    extractionValues: z
      .array(z.string().min(1, 'Extraction site should not be empty'))
      .optional()
      .superRefine((extractionValues, context) => {
        if (implantReportInputs.reportType === 'EXTRACTION') {
          if (!extractionValues || extractionValues.length === 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Extraction site should not be empty',
            })
          }
        }
      }),
    additionalComments: z
      .string()
      .optional()
      .superRefine((additionalComments, context) => {
        if (implantReportInputs.reportType === 'OTHER') {
          if (!additionalComments || additionalComments.length === 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Additional comments should not be empty',
            })
          }
        }
      }),
    nextApptDate: z
      .date()
      .optional()
      .superRefine((nextApptDate, context) => {
        if (
          implantReportInputs.reportType === 'FIRST_STAGE' ||
          implantReportInputs.reportType === 'SINGLE'
        ) {
          if (!nextApptDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Next appointment date should not be empty',
            })
          }
        }
      }),
    nextApptInMonths: z
      .number()
      .optional()
      .superRefine((nextApptInMonths, context) => {
        if (
          implantReportInputs.reportType === 'FIRST_STAGE' ||
          implantReportInputs.reportType === 'SINGLE'
        ) {
          if (!nextApptInMonths && nextApptInMonths !== 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Next appointment date should not be empty',
            })
          }
          if (nextApptInMonths === 0) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Next appointment date should not be zero',
            })
          }
        }
      }),
    secondStageAbutmentType: z
      .string()
      .optional()
      .superRefine((secondStageAbutmentType, context) => {
        if (implantReportInputs.reportType === 'FIRST_STAGE') {
          if (
            !secondStageAbutmentType ||
            secondStageAbutmentType.length === 0
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Second stage abutment type should not be empty',
            })
          }
        }
      }),
  })

  type TValidationError = {
    extractionValues?: string[] | undefined
    additionalComments?: string[] | undefined
    nextApptDate?: string[] | undefined
    nextApptInMonths?: string[] | undefined
    secondStageAbutmentType?: string[] | undefined
  }

  const [validationErrors, setValidationErrors] =
    useState<TValidationError | null>(null)

  const validateAndSubmit = () => {
    const validationResult = implantReportSchema.safeParse(
      implantReportInputs.reportInputs
    )
    if (!validationResult.success) {
      setValidationErrors(validationResult.error.formErrors.fieldErrors)
      return
    }

    const reportType =
      implantReportInputs.reportType as keyof typeof ImplantReportType
    if (reportAlreadySent(reportType)) {
      setReportAlreadySentModal(true)
      return
    }
    submitReport()
    setMutationSuccess(undefined)
  }

  const submitOverride = () => {
    try {
      setImplantReportInputs((prevState) => ({
        ...prevState,
        submitOverride: true,
      }))
    } catch (error) {
      console.error('Error in submitOverride:', error)
      setMutationSuccess(false)
    }
  }

  // solves async state update on submitOverride flag issue
  useEffect(() => {
    if (implantReportInputs.submitOverride) {
      submitReport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implantReportInputs.submitOverride])

  const handleOnClose = () => {
    setInputModalOpen(false)
    setImplantReportInputs({
      surgeryId,
      reportType: undefined,
      referringPhysicianEmail: implantReportInputs.referringPhysicianEmail,
      referringPhysicianLastName:
        implantReportInputs.referringPhysicianLastName,
      reportInputs: {
        assetIds: assetIds,
      },
      submitOverride: false,
    })
    setMutationSuccess(undefined)
    setExtractionValues([''])
  }

  const submitReport = async () => {
    try {
      setImplantReportInputs((prevState) => ({
        ...prevState,
        reportInputs: {
          ...prevState.reportInputs,
        },
      }))

      const result = await sendImplantReport()

      if (result.data?.sendImplantReportEmail?.success) {
        setMutationSuccess(true)
        setInputModalOpen(false)
        setReportAlreadySentModal(false)
        setImplantReportInputs((prevState) => ({
          ...prevState,
          submitOverride: false,
        }))
      } else {
        setMutationSuccess(false)
        setReportAlreadySentModal(false)
        setErrorMessage(result.data?.sendImplantReportEmail?.message)
      }
    } catch (error) {
      setMutationSuccess(false)
    }
  }

  return {
    validationErrors,
    extractionValues,
    handleOnClose,
    handleChangeFormData,
    validateAndSubmit,
    sendImplantReportMutation,
    handleAddExtractionInput,
    handleChangeExtractionInput,
    reportAlreadySentModal,
    setReportAlreadySentModal,
    submitReport,
    submitOverride,
    doNotRenderSubmitOverride,
  }
}
