import {
  ImplantReportStatus,
  ImplantReportType,
  Procedures,
  Surgery,
  ImplantReportEmailInput,
  ImplantReportInputs,
  AssetData,
  Scan,
} from 'common/types'
import { z } from 'zod'

export interface ImplantReportSenderProps {
  surgeryId: Surgery['_id']
  implantReportStatus?: ImplantReportStatus[]
  surgeryDate: Procedures['dateTime']
  assetGroups: AssetData[]
}

export interface ViewImplantReportModalProps {
  implantReportStatus: ImplantReportStatus[]
  formatEnumValue: (value: keyof typeof ImplantReportType) => string
  viewReportsModalOpen: boolean
  setViewReportsModalOpen: (value: boolean) => void
}

export interface ImplantReportSenderModalLogicProps {
  surgeryId: Surgery['_id']
  implantReportStatus?: ImplantReportStatus[]
  implantReportInputs: ImplantReportEmailInput
  setImplantReportInputs: React.Dispatch<
    React.SetStateAction<ImplantReportEmailInput>
  >
  unformatEnumValue: (value: string) => ImplantReportType
  setMutationSuccess: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  setInputModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  assetIds: Scan['_id'][]
}

export interface ImplantReportSenderModalProps
  extends ImplantReportSenderModalLogicProps {
  inputModalOpen: boolean
  formatEnumValue: (value: keyof typeof ImplantReportType) => string
  mutationSuccess?: boolean
  errorMessage?: string
  surgeryDate: Procedures['dateTime']
}

export interface ReferringPhysicianInputsLogicProps {
  implantReportInputs: ImplantReportEmailInput
  setImplantReportInputs: React.Dispatch<
    React.SetStateAction<ImplantReportEmailInput>
  >
  setAddPhysicianMessage: React.Dispatch<React.SetStateAction<string>>
}

export type ImplantReportInputValue<Key extends keyof ImplantReportInputs> =
  ImplantReportInputs[Key]

export type ModalProps = {
  open: boolean
  type?: 'create' | 'edit'
}

export const physicianDetailsFormSchema = z
  .object({
    _id: z.string().optional(),
    firstName: z
      .string()
      .min(1, { message: 'Physician first name cannot be empty' }),
    lastName: z
      .string()
      .min(1, { message: 'Physician last name cannot be empty' }),
    email: z.string().email({ message: 'Invalid email address' }),
    confirmEmail: z.string().email({ message: 'Invalid email address' }),
  })
  .refine((data) => data.email === data.confirmEmail, {
    path: ['confirmEmail'],
    message: 'Emails must match',
  })
