import { Button, CircularProgress, Skeleton, Typography } from '@mui/material'

import { useTrayDetails } from './TrayDetails.logic'

import './TrayDetails.scss'
import { Link } from 'react-router-dom'
import { ArrowBack, Delete } from '@mui/icons-material'
import TrayProduct from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/TrayProduct'
import { LoadingButton } from 'components/mui'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import ApproveRejectTray from 'components/molecules/ApproveRejectTray/ApproveRejectTray'
import { useUser } from 'app/User'

const TrayDetails = () => {
  const {
    loading,
    error,
    trayItem,
    deleting,
    handleDeleteTray,
    subTraysTableBodyCells,
    subTraysTableHeaderCells,
    isApproveRejectTrayOpen,
    handleApproveRejectTrayClick,
    isApproving,
    isRejecting,
    handleUpdateTrayApprovalStatus,
    isSPD,
    trayDetails,
  } = useTrayDetails()

  return (
    <div className="tray-details_wrapper">
      <div data-testid="header" className="header">
        <div className="buttons_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            to=".."
            component={Link}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <div className="right_buttons">
            {isSPD && trayDetails?.trayCategory !== 'facility' && (
              <LoadingButton
                variant="outlined"
                onClick={handleApproveRejectTrayClick}
              >
                Approve/ Reject Tray
              </LoadingButton>
            )}

            <LoadingButton
              className="delete"
              dataTestId="delete-tray-button"
              loading={deleting}
              disabled={deleting}
              mode="delete"
              sx={{ width: deleting ? '50px' : '30px' }}
              onClick={handleDeleteTray}
            >
              <Delete sx={{ width: '17px', height: '17px' }} />
            </LoadingButton>
          </div>
        </div>

        <Typography variant="h1">Tray Details</Typography>
      </div>

      <ApproveRejectTray
        open={isApproveRejectTrayOpen}
        onClose={handleApproveRejectTrayClick}
        isApproving={isApproving}
        isRejecting={isRejecting}
        handleApproval={() => handleUpdateTrayApprovalStatus('approved')}
        handleRejection={() => handleUpdateTrayApprovalStatus('rejected')}
      />

      <div className="content">
        {loading && !error && <CircularProgress />}

        {error && !loading && (
          <Typography className="error" variant="body1">
            {error.message}
          </Typography>
        )}

        {!error && !loading && trayItem && (
          <div className="sub-trays-table_container">
            <DataTable
              tableHeaders={subTraysTableHeaderCells}
              tableRows={subTraysTableBodyCells(trayItem.subItems)}
              isLoading={false}
              isErrored={false}
              disableSorting
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TrayDetails
