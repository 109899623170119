import { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { IconButton, Typography, Box } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import ErrorDisplay from 'components/ErrorDisplay'
import dayjs from 'lib/dayjs'
import { useDisablePolling } from 'lib/useDisablePolling'
import { useGetSurgeriesQuery } from 'lib/apollo/hooks'
import CustomDatePicker from 'components/molecules/DatePicker/DatePicker'
import { ProcedureListTable } from './ProcedureListTable'
import { useNavigate } from 'react-router-dom'
import Button from 'components/molecules/Button/Button'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { useFlags } from 'launchdarkly-react-client-sdk'

const initialBefore = dayjs().endOf('day').toDate()
const initialAfter = dayjs().startOf('day').toDate()

function ProceduresNurse() {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const disablePolling = useDisablePolling()
  const [beforeDate, setBeforeDate] = useState(initialBefore)
  const [afterDate, setAfterDate] = useState(initialAfter)
  const flags = useFlags()
  const { loading, error, data, refetch } = useGetSurgeriesQuery(
    {
      before: beforeDate,
      after: afterDate,
      limit: 0,
    },
    {
      disablePolling,
    }
  )

  const surgeries = data && data.getSurgeries?.results
  const isErrored = !loading && error

  const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const handleBackDay = () => {
    setBeforeDate(dayjs(beforeDate).subtract(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).subtract(1, 'day').toDate())
  }

  const handleForwardDay = () => {
    setBeforeDate(dayjs(beforeDate).add(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).add(1, 'day').toDate())
  }

  const handleNavigate = () => navigate('/new-procedure')

  return (
    <Box
      aria-label="procedure-list-container"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
      width="100%"
    >
      <Box
        aria-label="procedures-nurse-header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h1" fontSize={isMd ? 20 : 24}>
          Procedure List
        </Typography>

        {!isMobile ? (
          <Typography
            variant="subtitle1"
            fontSize={isMd ? 16 : 20}
            fontWeight={500}
          >
            {dayjs().format('ddd MMM D, YYYY')}
          </Typography>
        ) : null}
        {flags?.allowAddingProceduresManually ? (
          <Button onClick={handleNavigate} variant="contained">
            Add Procedure
          </Button>
        ) : null}
      </Box>

      <Box
        aria-label="date-picker-box"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        display="flex"
      >
        <IconButton onClick={handleBackDay} size="small">
          <NavigateBefore fontSize="large" />
        </IconButton>

        <CustomDatePicker
          label="Procedure List Date"
          date={afterDate}
          renderDatePicker={false}
        />

        <IconButton onClick={handleForwardDay} size="small">
          <NavigateNext fontSize="large" />
        </IconButton>
      </Box>

      {/* If there is no surgery data and there is an error, show the 
      server connection message */}
      {isErrored && !surgeries && <ErrorDisplay pathForward={refetch} />}

      {/* If there is an error, but there are available surgeries, indicate to 
      the user without interrupting the normal app workflow, that one or more 
      of the surgeries could ot be displayed due to server errors, and to check 
      with the administrator.*/}
      {isErrored && surgeries && (
        <Typography variant="h4">
          Our server encountered an issue with 1 or more surgeries that could
          not be displayed. Please contact the administrator for further
          assistance.
        </Typography>
      )}

      <ProcedureListTable events={surgeries} loading={loading} />
    </Box>
  )
}

export default ProceduresNurse
